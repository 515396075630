<template>
  <v-app>      
    <Alert />    
    <ToolbarLogin />  
    <v-container text-xs-center justify-center wrap class="container-login">  
      <v-layout wrap align-center>
        <LeftAuth /> 
        <FormSenha /> 
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>

  import Alert from '@/_components/shared/Alert';
  import LeftAuth from '@/_components/private/auth/LeftAuth';
  import FormSenha from '@/_components/private/auth/FormSenha';
  import ToolbarLogin from '@/_components/private/auth/Toolbar';

  export default {
    components: {
      Alert,
      LeftAuth,
      FormSenha,
      ToolbarLogin
    }
  }
</script>

<style lang="scss" scoped>
  .container-login {
    height: 100vh;
    max-width: 100vw !important;
    padding: 0;
    margin: 0;
  }
</style>