<template>
    <v-app>

        <Alert />
    
        <div class="app"> 

            <SideMenu /> 

            <Header />
        
            <v-main class="content">        
                <div class="cardContent">
                    <v-card class="cardItem">
                        <form>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Criar Novo Tipo</h3>
                                </v-col>
                            </v-row>                            

                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field
                                        v-model="nome"
                                        label="Nome"
                                        required
                                        solo
                                        light
                                        v-mask="customTokens"
                                    ></v-text-field>
                                </v-col>
                            </v-row>                        

                            <v-row class="rowButton">
                                <v-col cols="12" md="12">
                                    <v-btn
                                        small                                            
                                        color="#ea6936" 
                                        dark 
                                        v-on:click="salvarDados"
                                    >
                                        Salvar Dados
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </form>
                    </v-card>
                </div>
            </v-main>
        </div>   
    
    </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';
    import http from '@/_services/http';
    import store from "@/_store";
    import {mask} from 'vue-the-mask';

    export default {
        components: {
            Alert,
            Header,
            SideMenu
        },
        directives: {
            mask
        },
        data () {
            return {   
                nome: "",
                customTokens: {
                    mask: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
                    tokens: {
                        X: {
                            pattern: /^[a-zA-Z]*$/,
                            transform: v => v.toLocaleUpperCase()
                        }
                    }
                },
            }
        },
         methods: {
            salvarDados(){
                let objEnvio = {
                    "nome": this.nome
                };

                http.post("types/add", objEnvio).then((response) => {
                    if(!response.data.status) {
                        store.dispatch("Notification/alert", {
                            type: "warning",
                            message: response.data.message,
                            timeClose: 3000
                        });
                    } else {           
                        store.dispatch("Notification/alert", {
                            type: "info",
                            message: "Dados salvos com sucesso",
                            timeClose: 3000
                        });   
                        setTimeout(function(){
                            window.location.href = "/tipos";
                        }, 2000);                      
                    }
                }).catch((error) => {
                    console.log(error);
                    store.dispatch("Notification/alert", {
                        type: "error",
                        message: "Ocorreu um erro",
                        timeClose: 3000
                    });
                });
            },
        }
    }  
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardItem {
        float: left;
        display: block;
        height: 100%;
    }
</style>