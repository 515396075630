<template>
    <v-app>
    
        <Alert /> 

        <div class="app"> 

            <SideMenu /> 

            <Header />
        
            <v-main class="content">        
                <div class="cardContent">
                    <v-card class="cardItem">
                        <form>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Criar Novo Personalizado</h3>
                                </v-col>
                            </v-row>  

                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-select
                                        :items="listaEmpresa"
                                        v-model="empresaSelecionada"
                                        label="Empresa"
                                        item-text="nome"
                                        item-value="_id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>
                            </v-row>                          

                            <v-row>
                                <v-col cols="12" md="10">
                                    <v-select
                                        :items="listaEquipamentos"
                                        v-model="equipamento"
                                        label="Local Equipamento"
                                        item-text="nome"
                                        item-value="_id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="2" style="padding-top: 20px;">
                                    <v-btn
                                        small                                            
                                        color="#ea6936" 
                                        dark 
                                        v-on:click="modalLocal"
                                    >
                                        Novo Local
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row>                                
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="nome"
                                        label="Nome"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>  
                            </v-row>   

                            <v-row>                                
                                <v-col cols="12">
                                    <v-textarea
                                        solo
                                        light
                                        v-model="descricao"
                                        label="Descrição"
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                            <v-row>    
                                <v-col cols="12" md="9">
                                    <v-text-field
                                        v-model="identificacao"
                                        label="Identificação"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>  
                                <v-col cols="12" md="3">
                                    <v-text-field
                                        v-model="validade"
                                        label="Validade"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>                                                                              
                            </v-row>   
                           
                            <v-row>       
                                <v-col cols="12" md="4">
                                    <v-select
                                        :items="listaModelos"
                                        v-model="modelo"
                                        label="Modelo"
                                        item-text="nome"
                                        item-value="_id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>      
                                <v-col cols="12" md="4">
                                    <v-select
                                        :items="[
                                            'Mensal', 
                                            'Bimestral', 
                                            'Trimestral', 
                                            'Semestral', 
                                            'Anual'
                                        ]"
                                        v-model="periodo"
                                        label="Periodo da Inspeção"
                                        item-text="value"
                                        item-value="id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>                           
                                <v-col cols="12" md="4">
                                    <v-select
                                        :items="[
                                            'Ativo', 
                                            'Vencido', 
                                            'Manutenção', 
                                            'Inativo', 
                                            'Condenado'
                                        ]"
                                        v-model="situacao"
                                        label="Situação"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>                                    
                            </v-row>
                           
                            <v-row class="rowButton">
                                <v-col cols="12" md="12">
                                    <v-btn
                                        small                                            
                                        color="#ea6936" 
                                        dark 
                                        v-on:click="salvarDados"
                                    >
                                        Salvar Dados
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </form>
                    </v-card>
                </div>
            </v-main>

            <v-dialog v-model="dialog" width="500px">            
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Local de Equipamento</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="nomeLocal"
                                    label="Nome"
                                    required
                                    solo
                                    light
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="#ea6936"
                            text
                            @click="dialog = false"
                        >
                            Fechar
                        </v-btn>
                        <v-btn
                            color="#ea6936"
                            text
                            @click="salvarLocal"
                        >
                            Salvar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
        </div>   
    
    </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';
    import http from '@/_services/http';
    import store from "@/_store";

    export default {
        components: {
            Alert,
            Header,
            SideMenu
        },
        data () {
            return {   
                dialog: false,
                nomeLocal: "",
                listaEmpresa: [],
                listaEquipamentos: [],
                listaModelos: [],
                empresaSelecionada: "",
                equipamento: "",
                nome: "",
                descricao: "",
                identificacao: "",
                validade: "",
                modelo: "",
                periodo: "",
                situacao: "",
            }
        },
        created () {
            this.initialize();
        },
        methods: {
            initialize () {
                http.get("locals/getAll")
                    .then((response) => {
                        this.listaEquipamentos = response.data.Response;
                        this.buscarEmpresa();
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            buscarEmpresa(){
                http.get("companies/getAll")
                    .then((response) => {
                        this.listaEmpresa = response.data.Response.filter(item => {
                            return item.ativo == true;
                        });
                        this.buscarModelos();
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            buscarModelos(){
                http.get("types/getAll")
                    .then((response) => {
                        this.listaModelos = response.data.Response.filter(item => {
                            if(item.ativo == true && item.nome !== "EXTINTOR" && item.nome !== "HIDRANTE"){
                                return true;
                            }
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            salvarDados(){
                let objEnvio = {
                    "empresa": this.empresaSelecionada,
                    "local": this.equipamento,
                    "nome": this.nome,
                    "descricao": this.descricao,
                    "identificacao": this.identificacao,
                    "validade": this.validade,
                    "tipo": this.modelo,
                    "periodicidade_inspecao": this.periodo,
                    "status": this.situacao,
                    "ativo": true
                }
                http.post("custom-item/add", objEnvio).then((response) => {
                    if(!response.data.status) {
                        store.dispatch("Notification/alert", {
                            type: "warning",
                            message: response.data.message,
                            timeClose: 3000
                        });
                    } else {                            
                        store.dispatch("Notification/alert", {
                            type: "info",
                            message: "Dados salvos com sucesso",
                            timeClose: 3000
                        });       
                        setTimeout(function(){
                            window.location.href = "/personalizados";  
                        }, 2000);                              
                    }
                }).catch((error) => {
                    console.log(error);
                    store.dispatch("Notification/alert", {
                        type: "error",
                        message: "Ocorreu um erro",
                        timeClose: 3000
                    });
                });
            },

            salvarLocal(){
                let objEnvio = {
                    "nome": this.nomeLocal,
                    "modelo": "Personalizada",
                    "descricao": "-"
                };
                http.post("locals/add", objEnvio).then((response) => {
                    if(!response.data.status) {
                        store.dispatch("Notification/alert", {
                            type: "warning",
                            message: response.data.message,
                            timeClose: 3000
                        });
                    } else {           
                        store.dispatch("Notification/alert", {
                            type: "info",
                            message: "Dados salvos com sucesso",
                            timeClose: 3000
                        });   
                        setTimeout(function(){
                            window.location.href = "/personalizado/novo";
                        }, 2000);                                            
                    }
                }).catch((error) => {
                    console.log(error);
                    store.dispatch("Notification/alert", {
                        type: "error",
                        message: "Ocorreu um erro",
                        timeClose: 3000
                    });
                });
            },

            modalLocal(){
                this.dialog = true;
            },
        }
  }  
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardItem {
        float: left;
        display: block;
        height: 100%;
    }
</style>