export default {
    namespaced: true,
    state: {
        alert: {
            typeAlert: "",
            messageAlert: "",
            statusAlert: false
        }
    },
    mutations: {
        alert(state, { type, message, timeClose }) {           
            state.alert.typeAlert = type;
            state.alert.messageAlert = message;
            state.alert.statusAlert = true;
            setTimeout(function(){
                state.alert.statusAlert = false;
            }, timeClose);
        },
        showHide(state, { status }) {
            state.alert.statusAlert = status;
        }
    },
    actions: {
        alert(context, { type, message, timeClose }) {
            context.commit("alert", { type, message, timeClose });
        },
        showHide(context, { status }) {
            context.commit("showHide", { status });
        }
    },
}