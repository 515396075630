<template>
    <v-app>

        <Alert />
    
        <div class="app"> 
        
            <SideMenu /> 

            <Header />
     
            <v-main class="content">        
                <div class="cardContent">
                    <v-container>
                        <v-card class="cardItem">
                    
                            <form>

                                <v-row>
                                    <v-col cols="12" md="12">
                                        <h3>Relatórios de Extintores</h3>
                                    </v-col>
                                </v-row> 

                                <v-row>
                                    <v-col cols="12" md="5">
                                        <v-select
                                            :items="['Inspecionados', 'Não Inspecionados', 'Vencimento Carga/Manutenção', 'Ocorrências', 'Status']"
                                            v-model="tipoRelatorio"
                                            label="Tipo"
                                            required
                                            solo
                                            light
                                        ></v-select>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="3">
                                        <v-select
                                            :items="[
                                                'Todos',
                                                'Janeiro', 
                                                'Fevereiro', 
                                                'Março', 
                                                'Abril', 
                                                'Maio',
                                                'Junho',
                                                'Julho',
                                                'Agosto',
                                                'Stembro',
                                                'Outubro',
                                                'Novembro',
                                                'Dezembro'
                                            ]"
                                            v-model="mes"
                                            label="Mês"
                                            required
                                            solo
                                            light
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-select
                                            :items="[
                                                'Todos',
                                                '2021', 
                                                '2022', 
                                                '2023'
                                            ]"
                                            v-model="ano"
                                            label="Ano"
                                            required
                                            solo
                                            light
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            v-model="dataUltimaRecarga"
                                            label="Número selo Inmetro ou Chassi"
                                            required
                                            solo
                                            light
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-select
                                            :items="[
                                                'Ativo', 
                                                'Vencido', 
                                                'Manutenção', 
                                                'Inativo', 
                                                'Condenado'
                                            ]"
                                            v-model="status"
                                            label="Status"
                                            required
                                            solo
                                            light
                                        ></v-select>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-select
                                            :items="listaEquipamentos"
                                            v-model="local"
                                            label="Local"
                                            item-text="nome"
                                            item-value="_id"
                                            required
                                            solo
                                            light
                                        ></v-select>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-select
                                            :items="listaEmpresa"
                                            v-model="empresa"
                                            label="Empresa"
                                            item-text="nome"
                                            item-value="_id"
                                            required
                                            solo
                                            light
                                        ></v-select>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-select
                                            :items="listaUsuarios"
                                            v-model="usuario"
                                            item-text="nome"
                                            item-value="_id"
                                            label="Inspetor"
                                            required
                                            solo
                                            light
                                        ></v-select>
                                    </v-col>                            
                                </v-row>

                                <v-row class="rowButton">
                                    <v-col cols="12" md="12">
                                        <v-btn
                                            small                                            
                                            color="#ea6936" 
                                            dark 
                                            class="mr-3"
                                        >
                                            Gerar Relatório PDF
                                        </v-btn>
                                        <v-btn
                                            small                                            
                                            color="#ea6936" 
                                            dark 
                                        >
                                            Gerar Relatório Excel
                                        </v-btn>
                                    </v-col>
                                </v-row>

                            </form>
                    
                        </v-card>
                    </v-container>        
                </div>
            </v-main>
    
        </div>   
  
    </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';
    import http from '@/_services/http';
    import store from "@/_store";

    export default {
        components: {
            Alert,
            Header,
            SideMenu
        },
        data () {
            return {
                tipoRelatorio: "",
                mes: "",
                ano: "",
                dataUltimaRecarga: "",
                status: "",
                listaEquipamentos: [],
                local: "",
                inspetor: "",
                listaEmpresa: [],
                empresa: "",
                listaUsuarios: [],
                usuario: ""
            }
        },
        created () {
            this.initialize();
        },
        methods: {
            initialize () {
                http.get("locals/getAll")
                    .then((response) => {
                        this.listaEquipamentos = response.data.Response;    
                        this.buscarEmpresa();                    
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            buscarEmpresa(){
                http.get("companies/getAll")
                    .then((response) => {
                        this.listaEmpresa = response.data.Response.filter(item => {
                            return item.ativo == true;
                        });
                        this.buscaUsuarios();
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            buscaUsuarios(){
                http.get("users/getAll")
                    .then((response) => {
                        this.listaUsuarios = response.data.Response.filter(item => {
                            return item.ativo == true && item.permissao == 1;
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            }
        }
    }  
</script>

<style scoped lang="scss">  
</style>