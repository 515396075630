<template>
    <v-app>

        <Alert />
    
        <div class="app"> 

            <SideMenu /> 

            <Header />
        
            <v-main class="content">        
                <div class="cardContent">
                    <v-card class="cardItem">
                        <form>
                            
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Criar Nova Empresa</h3>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="12" lg="4">
                                    <v-text-field
                                        v-model="cnpj"
                                        label="CNPJ"
                                        required
                                        solo
                                        light
                                        v-mask="'##.###.###/####-##'"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field
                                        v-model="nome"
                                        label="Nome"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field
                                        v-model="razaoSocial"
                                        label="Razão Social"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="12" lg="3">
                                    <v-text-field
                                        v-model="cep"
                                        label="CEP"
                                        required
                                        solo
                                        light
                                        v-mask="'#####-###'"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" lg="5">
                                    <v-text-field
                                        v-model="endereco"
                                        label="Endereço"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" lg="1">
                                    <v-text-field
                                        v-model="numero"
                                        label="Nº."
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" lg="3">
                                    <v-text-field
                                        v-model="complemento"
                                        label="Complemento"                                        
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>               
                            </v-row>

                           <v-row>
                                <v-col cols="12" md="12" lg="3">
                                    <v-text-field
                                        v-model="bairro"
                                        label="Bairro"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" lg="6">
                                    <v-text-field
                                        v-model="cidade"
                                        label="Cidade"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" lg="3">
                                    <v-select
                                        :items="[
                                            'Acre - AC',
                                            'Alagoas - AL',
                                            'Amapá - AP',
                                            'Amazonas - AM',
                                            'Bahia - BA',
                                            'Ceará - CE',
                                            'Distrito Federal* - DF',
                                            'Espírito Santo - ES',
                                            'Goiás - GO',
                                            'Maranhão - MA',
                                            'Mato Grosso - MT',
                                            'Mato Grosso do Sul - MS',
                                            'Minas Gerais - MG',
                                            'Pará - PA',
                                            'Paraíba - PB',
                                            'Paraná - PR',
                                            'Pernambuco - PE',
                                            'Piauí - PI',
                                            'Rio de Janeiro - RJ',
                                            'Rio Grande do Norte - RN',
                                            'Rio Grande do Sul - RS',
                                            'Rondônia - RO',
                                            'Roraima - RR',
                                            'Santa Catarina - SC',
                                            'São Paulo - SP',
                                            'Sergipe - SE',
                                            'Tocantins - TO'
                                        ]"
                                        v-model="uf"
                                        label="UF"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>
                           </v-row>

                           <v-row>
                               <v-col cols="12" md="12">
                                    <input type="hidden" id="idEmpresa" />
                                    <v-file-input
                                        :rules="rules"
                                        accept="image/png"
                                        placeholder="Logotipo"
                                        prepend-icon="mdi-camera"
                                        label="Logotipo"
                                        id="fileUpload"
                                        name="fileUpload"
                                        show-size
                                        solo
                                    ></v-file-input>
                               </v-col>
                           </v-row>

                            <v-row class="rowButton">
                                <v-col cols="12" md="12">
                                    <v-btn
                                        small                                            
                                        color="#ea6936" 
                                        dark 
                                        v-on:click="salvarDados"
                                    >
                                        Salvar Dados
                                    </v-btn>                                
                                </v-col>
                            </v-row>

                        </form>
                    </v-card>
                </div>
            </v-main>
        </div>   
    
    </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';
    import {mask} from 'vue-the-mask';
    import store from "@/_store";
    import http from '@/_services/http';
    import axios from 'axios';

  export default {
    components: {
        Alert,
        Header,
        SideMenu
    },
    directives: {
        mask
    },
    data () {
        return {   
            cnpj: "",
            nome: "",
            razaoSocial: "",
            cep: "",
            endereco: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            uf: "",  
            fileUpload: "",
            idEmpresa: "",
            objRetorno: "",
            rules: [
                value => !value || value.size < 5000000 || 'Logotipo size should be less than 5MB!',
            ],
        }
    },
    methods: {
        salvarDados(){
            let objEnvio = {
                "cnpj": this.cnpj,
                "nome": this.nome,
                "razao_social": this.razaoSocial,
                "cep": this.cep,
                "endereco": this.endereco,
                "numero": this.numero,
                "complemento": this.complemento,
                "bairro": this.bairro,
                "cidade": this.cidade,
                "estado": this.uf
            };

            http.post("companies/add", objEnvio).then((response) => {
                if(!response.data.status) {
                    store.dispatch("Notification/alert", {
                        type: "warning",
                        message: response.data.message,
                        timeClose: 3000
                    });
                } else {           
                    console.log(response.data);
                    this.objRetorno = response.data.Response;
                    this.idEmpresa = response.data.Response._id;
                    this.salvarImg();                       
                }
            }).catch((error) => {
                console.log(error);
                store.dispatch("Notification/alert", {
                    type: "error",
                    message: "Ocorreu um erro",
                    timeClose: 3000
                });
            });
        },

        salvarImg: function(){
            let formData = new FormData();
            let imagefile = document.querySelector('#fileUpload');
            formData.append("fileUpload", imagefile.files[0]);
            formData.append("idEmpresa", this.idEmpresa);

            axios.post("/php/saveFile.php", formData, {
                baseURL: "https://fire.almeidabarreto.com.br",
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                let url = response.data[0].split("..");
                url = url[1];
                url = "https://fire.almeidabarreto.com.br" + url;
                this.salvarLogo(url);
            }).catch((error) => {
                console.log(error);
                store.dispatch("Notification/alert", {
                    type: "error",
                    message: "Ocorreu um erro",
                    timeClose: 3000
                });
            });
        },

        salvarLogo: function(logotipo){
            let objEnvio = {
                "id": this.objRetorno._id,
                "cnpj": this.objRetorno.cnpj,
                "nome": this.objRetorno.nome,
                "razao_social": this.objRetorno.razaoSocial,
                "cep": this.objRetorno.cep,
                "endereco": this.objRetorno.endereco,
                "numero": this.objRetorno.numero,
                "complemento": this.objRetorno.complemento,
                "bairro": this.objRetorno.bairro,
                "cidade": this.objRetorno.cidade,
                "estado": this.objRetorno.uf,
                "logotipo": logotipo
            };
            let url = "companies/edit/" + this.objRetorno._id;
            http.post(url, objEnvio).then((response) => {
                if(!response.data.status) {
                    store.dispatch("Notification/alert", {
                        type: "warning",
                        message: response.data.message,
                        timeClose: 3000
                    });
                } else {                            
                    store.dispatch("Notification/alert", {
                        type: "info",
                        message: "Dados salvos com sucesso",
                        timeClose: 3000
                    });   
                    setTimeout(function(){
                       window.location.href = "/empresas";
                    }, 2000);                             
                }
            }).catch((error) => {
                console.log(error);
                store.dispatch("Notification/alert", {
                    type: "error",
                    message: "Ocorreu um erro",
                    timeClose: 3000
                });
            });
        }
    }
  }  
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardItem {
        float: left;
        display: block;
        height: 100%;
    }
</style>