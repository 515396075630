<template>
  <v-app>

    <Alert />    
    
    <div class="app"> 

      <SideMenu /> 

      <Header />
     
      <v-main class="content">        
        <div class="cardContent">
            <v-container>
                <v-card class="cardItem">
                    <v-tabs 
                        v-model="tab"
                        color="black"
                        flat
                        grow
                    >
                        <v-tab>Perfil</v-tab>
                        <v-tab>Senha</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        
                        <v-tab-item>
                            <form>
                                
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <h3>Dados Pessoais</h3>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            v-model="nomeCompleto"
                                            label="Nome Completo *"
                                            required
                                            solo
                                            light
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="12" lg="6">
                                        <v-text-field
                                            v-model="email"
                                            label="E-mail *"
                                            required
                                            solo
                                            light
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12" lg="6">
                                        <v-text-field
                                            v-model="cpf"
                                            label="CPF"
                                            required
                                            solo
                                            light
                                            v-mask="'###.###.###-##'"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row class="rowButton">
                                    <v-col cols="12" md="12">
                                        <v-btn
                                            small                                            
                                            color="#ea6936" 
                                            dark 
                                            v-on:click="salvarDados"
                                        >
                                            Salvar Dados
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <br>

                            </form>
                        </v-tab-item>

                        <v-tab-item>
                            <form>
                                
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <h3>Alterar Senha</h3>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="12" lg="6">
                                        <v-text-field
                                            v-model="novaSenha"
                                            :append-icon="showNS ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="showNS ? 'text' : 'password'"
                                            label="Nova Senha *"
                                            :rules="[rules.required, rules.min]"
                                            required
                                            solo
                                            light
                                            @click:append="showNS = !showNS"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12" lg="6">
                                        <v-text-field
                                            v-model="confirmarNovaSenha"
                                            :append-icon="showCNS ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="showCNS ? 'text' : 'password'"
                                            label="Confirmar Nova Senha *"
                                            :rules="[rules.required, rules.min]"
                                            required
                                            solo
                                            light
                                            @click:append="showCNS = !showCNS"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="rowButton">
                                    <v-col cols="12" md="12">
                                        <v-btn
                                            small                                            
                                            color="#ea6936" 
                                            dark 
                                            v-on:click="alterarSenha"
                                        >
                                            Salvar Dados
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <br>

                            </form>              
                        </v-tab-item>

                    </v-tabs-items>
                </v-card>
            </v-container>        
        </div>
      </v-main>
    
    </div>   
  
  </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';
    import {mask} from 'vue-the-mask';
    import store from "@/_store";
    import http from '@/_services/http';

  export default {
    components: {
        Alert,
        Header,
        SideMenu
    },
    directives: {
        mask
    },
    data () {
      return {
        tab: null,
        showNS: false,
        showCNS: false,
        nomeCompleto: "",
        email: "",
        cpf: "",
        novaSenha: "",
        confirmarNovaSenha: "",
        id: "",
        rules: {
            required: value => !!value || 'Obrigatório.',
            min: v => v.length >= 6 || 'Minimo 6 caracteres'
        },
      }
    },
    methods: {
        
        initValue: function(){
            let user = JSON.parse(localStorage.getItem("user"));            
            if(user){
                this.nomeCompleto = user.nome;
                this.email = user.email;
                this.cpf = user.cpf || "";
                this.id = user._id;
            }
        },
        
        salvarDados: function(){
            if(this.nomeCompleto && this.email && this.cpf){
                let user = JSON.parse(localStorage.getItem("user"));
                let objEnvio = {
                    "reset_senha": user.reset_senha,
                    "nome": this.nomeCompleto,
                    "username": user.username,
                    "email": this.email,
                    "senha": user.senha,
                    "permissao": user.permissao,
                    "empresas": user.empresas,
                    "cpf": this.cpf
                };

                let url = "users/edit/" + this.id;
                http.post(url, objEnvio).then((response) => {
                    if(!response.data.status) {
                        store.dispatch("Notification/alert", {
                            type: "warning",
                            message: response.data.message,
                            timeClose: 3000
                        });
                    } else {                            
                        store.dispatch("Notification/alert", {
                            type: "info",
                            message: "Dados alterados com sucesso",
                            timeClose: 3000
                        });       
                        setTimeout(function(){
                            console.log(response);
                            localStorage.setItem('user', JSON.stringify(response.data.Response));
                            window.location.href = "/perfil";  
                        }, 2000);                              
                    }
                }).catch((error) => {
                    console.log(error);
                    store.dispatch("Notification/alert", {
                        type: "error",
                        message: "Ocorreu um erro",
                        timeClose: 3000
                    });
                });

            } else {
                store.dispatch("Notification/alert", {
                    type: "warning",
                    message: "Por favor, preencher o campo de Nome Completo, E-mail e CPF",
                    timeClose: 3000
                });
            }
        },

        alterarSenha: function(){
            if(this.novaSenha && this.confirmarNovaSenha){
                if(this.novaSenha.length < 6 && this.confirmarNovaSenha.length < 6){
                    store.dispatch("Notification/alert", {
                        type: "warning",
                        message: "Os campos de Nova Senha e Confirmar Senha devem possuir no mínimo 6 caracteres",
                        timeClose: 3000
                    });
                } else {
                    if(this.novaSenha === this.confirmarNovaSenha){
                        let user = JSON.parse(localStorage.getItem("user"));
                        let objEnvio = {
                            "reset_senha": user.reset_senha,
                            "nome": user.nome,
                            "username": user.username,
                            "email": user.email,
                            "senha": this.novaSenha,
                            "permissao": user.permissao,
                            "empresas": user.empresas,
                            "cpf": user.cpf
                        };
                        let url = "users/edit/" + this.id;
                        http.post(url, objEnvio).then((response) => {
                            if(!response.data.status) {
                                store.dispatch("Notification/alert", {
                                    type: "warning",
                                    message: response.data.message,
                                    timeClose: 3000
                                });
                            } else {                            
                                store.dispatch("Notification/alert", {
                                    type: "info",
                                    message: "Senha alterada com sucesso",
                                    timeClose: 3000
                                });       
                                setTimeout(function(){
                                    console.log(response);
                                    localStorage.setItem('user', JSON.stringify(response.data.Response));
                                    window.location.href = "/perfil";  
                                }, 2000);                              
                            }
                        }).catch((error) => {
                            console.log(error);
                            store.dispatch("Notification/alert", {
                                type: "error",
                                message: "Ocorreu um erro",
                                timeClose: 3000
                            });
                        });
                    } else {
                        store.dispatch("Notification/alert", {
                            type: "warning",
                            message: "Os campos de Nova Senha e Confirmar Senha não conferem",
                            timeClose: 3000
                        });
                    }
                }
            } else {
                store.dispatch("Notification/alert", {
                    type: "warning",
                    message: "Por favor, preencher o campo de Nova Senha e Confirmar Senha",
                    timeClose: 3000
                });
            }
        },

    },
    created () {
        this.initValue();
    },
  }  
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardContent .container {
        padding: 0;
    }
    .v-tab.v-tab {
        font-weight: bold;
    }
    .v-window.v-item-group.theme--light.v-tabs-items {
        padding: 40px 5px 0px 5px;
    }    
</style>