export default {
    namespaced: true,
    state: {
        loading: {
            viewLoading: true
        }
    },
    mutations: {
        loading(state, { view }) {            
            state.loading.viewLoading = view;
        }
    },
    actions: {
        loading(context, { view }) {
            context.commit("loading", { view });
        }
    },
}