<template>
    <v-app>

        <Alert />
    
    <div class="app"> 

      <SideMenu /> 

      <Header />
     
      <v-main class="content">        
        <div class="cardContent">
            <v-container>

                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Pesquisar pela Inspeção dos Hidrantes"
                            single-line
                            hide-details
                            solo
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="4">
                        <v-btn 
                            small 
                            color="#ea6936" 
                            dark
                            @click="redirectPage('/manutencao/hidrantes')"
                        >
                            Retorno de Manutenção
                        </v-btn>
                    </v-col>
                </v-row>                
                
                <v-row>
                    <v-col cols="12" md="12">
                        <v-data-table
                            :headers="headers"
                            :items="base"
                            :search="search"
                            sort-by="id"
                            class="elevation-1"
                        >
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn
                                    x-small                                            
                                    color="#ea6936" 
                                    dark 
                                    @click="redirectPage('/inspecao/editar/', item.id)"
                                >
                                    Editar
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>

            </v-container>        
        </div>
      </v-main>
    
    </div>   
  
  </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';
    import http from '@/_services/http';
    import store from "@/_store";

    export default {
        components: {
            Alert,
            Header,
            SideMenu
        },

        data: () => ({
            search: '',
            headers: [
                {
                    text: 'Patrimonio',
                    align: 'start',
                    value: 'numero_patrimonio', 
                    sortable: true,                     
                    filterable: true          
                },
                {
                    text: 'Inspetor',
                    align: 'start',
                    value: 'inspetor', 
                    sortable: true,                     
                    filterable: true          
                },
                { 
                    text: 'Data',
                    align: 'center',
                    value: 'data', 
                    sortable: true,                     
                    filterable: true   
                },
                { 
                    text: '', 
                    value: 'actions',
                    sortable: false, 
                    filterable: false 
                },
            ],
            base: []
        }),

        created () {
            this.initialize();
        },

        methods: {
            initialize () {
                http.get("inspections/getAll")
                    .then((response) => {
                        this.base = response.data.Response;       
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            redirectPage (url, id){
                let link = url + (id || "");
                window.location.href = link;
            },
        },
    }
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardContent .container {
        padding: 0;
    }
    .cardTabPerfil {
        padding: 20px;
    }
    .v-tab.v-tab {
        font-weight: bold;
    }
    .v-window.v-item-group.theme--light.v-tabs-items {
        padding: 40px 5px 0px 5px;
    }
    .rowButton {
        text-align: center;
    }
</style>