<template>  
    <v-flex wrap xs12 sm12 md12 lg6 align-center class="d-flex cardRight">          
        
        <v-flex xs12 sm12 md12 lg6>

            <v-card class="cardItem" elevation="5">

                <h2 class="text-center titleLogin">LOGIN</h2>
            
                <v-flex xs12 sm12 md12 lg12>
                    <v-flex xs12 sm12 md12 lg10 class="mt-0 mb-0 ml-auto mr-auto">
                        <v-text-field 
                            solo
                            label="E-mail" 
                            required 
                            v-model="email"
                            light  
                            @keyup="uppercase"
                        ></v-text-field>                    
                    </v-flex> 
                </v-flex>        

                <v-flex xs12 sm12 md12 lg12>
                    <v-flex xs12 sm12 md12 lg10 class="mt-0 mb-0 ml-auto mr-auto">    
                        <v-text-field
                            solo
                            v-model="senha"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show1 ? 'text' : 'password'"
                            label="Senha"
                            hint="Mínimo 6 caracteres"
                            @click:append="show1 = !show1"    
                            light      
                        ></v-text-field>        
                    </v-flex>
                </v-flex>

                <v-flex sm12 md12 lg12 xs12>
                    <v-flex xs12 sm12 md12 lg10 class="mt-1 mb-0 ml-auto mr-auto"> 
                        <router-link to="/esqueciSenha" class="black--text text-link">Esqueceu a senha?</router-link>               
                    </v-flex>
                </v-flex>

                <v-flex xs12 sm12 md12 lg12>
                    <v-flex xs12 sm12 md12 lg6 class="mt-8 mb-0 ml-auto mr-auto"> 
                        <v-btn 
                            block
                            color="#ea6936" 
                            dark 
                            v-on:click="logarSistema"
                        >Entrar</v-btn>
                    </v-flex>
                </v-flex>                

            </v-card>

        </v-flex>
    </v-flex>
</template>

<script>

    import store from "@/_store";
    import http from '@/_services/http';

    export default {    
        data: () => ({   
            email: "",         
            show1: false,
            senha: "",
            rules: {
                required: value => !!value || 'Obrigatório.',
                min: v => v.length >= 6 || 'Minimo 6 caracteres'
            },
        }),
        methods: {
             uppercase: function () {
                this.email = this.email.toUpperCase();
            },

            logarSistema: function () { 

                let email = this.email;
                let password = this.senha;

                if(!email || !password){
                    store.dispatch("Notification/alert", {
                        type: "warning",
                        message: "Por favor, digite um e-mail e senha válidos",
                        timeClose: 3000
                    });
                } 
                else {

                    let objEnvio = {
                        "username": email,
                        "senha": password
                    };

                    store.dispatch("Notification/alert", {
                        type: "info",
                        message: "Aguarde, acessando o sistema...",
                        timeClose: 3000
                    });   

                    http.post("auth/login", objEnvio).then((response) => {
                        if(!response.data.status) {
                            store.dispatch("Notification/alert", {
                                type: "warning",
                                message: response.data.message,
                                timeClose: 3000
                            });
                        } else {                            
                            localStorage.setItem('token', response.data.Response.token);
                            this.buscarUsuario(response.data.Response.user);                                                  
                        }
                    }).catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
                }                   
            },

            buscarUsuario: function(id){
                let url = "users/get/" + id;
                http.get(url).then((response) => {
                    if(!response.data.status) {
                        store.dispatch("Notification/alert", {
                            type: "warning",
                            message: response.data.message,
                            timeClose: 3000
                        });
                    } else {                            
                        localStorage.setItem('user', JSON.stringify(response.data.Response[0]));
                        if(response.data.Response[0].empresas.length > 0){
                            if(response.data.Response[0].permissao == 0){
                                localStorage.setItem('company', 0);
                            } else {
                                localStorage.setItem('company',  JSON.stringify(response.data.Response[0].empresas[0]._id)); 
                            }
                        } else {
                            localStorage.setItem('company', 0);
                        }
                        window.location.href = "/painel";
                    }
                }).catch((error) => {
                    console.log(error);
                    store.dispatch("Notification/alert", {
                        type: "error",
                        message: "Ocorreu um erro",
                        timeClose: 3000
                    });
                });
            }
        } 
    }
</script>

<style lang="scss" scoped>
    .cardRight {
        background: #fdfdfd; 
        height: 100vh;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        @media only screen and (max-width: 992px) {
            padding: 0 20px;
        }
    }
    .text-link {
        text-decoration: none;
        font-size: 15px;
    }
    .titleLogin {
        margin: 0 20px 40px 20px;
        color: #ea6936;
        border-radius: 5px;
        padding: 5px;
    }
    .cardItem {
        padding: 40px 0;
    }
    @media only screen and (max-width: 992px) {
        .cardItem {
            padding: 40px;
        }
    }
</style>
