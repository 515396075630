import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './_store'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import '@babel/polyfill'

Vue.config.productionTip = false

//CONFIG AXIOS
axios.defaults.baseURL = 'https://ifire-dev.herokuapp.com/api-ifire/';
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
}

new Vue({
  router,
  store,
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app')