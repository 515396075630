import Vue from 'vue';
import Vuex from 'vuex';
import Notification from "./notification";
import Loading from "./loading";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Notification,
    Loading,
    
  }
})
