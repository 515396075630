<template>
    <v-app>

        <Alert />
    
        <div class="app"> 

            <SideMenu /> 

            <Header />
     
            <v-main class="content">        
                <div class="cardContent">
                    <v-card class="cardItem">
                        
                        <form>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Criar Perguntas para Tipo - <span>{{ tipoSelecionado }}</span></h3>
                                </v-col>
                            </v-row>                            

                            <v-row>
                                <v-col cols="12" md="10">
                                    <v-text-field
                                        v-model="pergunta"
                                        label="Pergunta"
                                        required
                                        solo
                                        light
                                    ></v-text-field>           
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-btn
                                        small                                            
                                        color="#ea6936" 
                                        dark 
                                        v-on:click="adicionar"
                                        class="mt-2"
                                    >
                                        Adicionar
                                    </v-btn>       
                                </v-col>
                            </v-row> 

                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-data-table
                                        :headers="headers"
                                        :items="listaPerguntas"
                                        :search="search"
                                        class="elevation-1"
                                    >
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <v-btn
                                                x-small                                            
                                                color="#ea6936" 
                                                dark 
                                                @click="excluir(item._id)"
                                            >
                                                Excluir
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>                       

                            <v-row class="rowButton">
                                <v-col cols="12" md="12">                                    
                                    <v-btn
                                        small                                            
                                        color="#777" 
                                        dark 
                                        class="ml-3"
                                        v-on:click="voltar"
                                    >
                                        Cancelar/Voltar
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </form>

                    </v-card>
                </div>
            </v-main>
    
        </div>   
  
    </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';
    import http from '@/_services/http';
    import store from "@/_store"; 

    export default {
        components: {
            Alert,
            Header,
            SideMenu
        },

        data: () => ({
            pergunta: "",
            tipo: {},
            tipoSelecionado: "",
            listaPerguntas: [],
            count: 0,
            search: '',
            headers: [
                { 
                    text: 'Id', 
                    align: 'left',
                    value: '_id', 
                    sortable: true,                     
                    filterable: false   
                },
                { 
                    text: 'Pergunta', 
                    align: 'left',
                    value: 'pergunta', 
                    sortable: true,                     
                    filterable: true   
                },
                { 
                    text: '', 
                    value: 'actions',
                    sortable: false, 
                    filterable: false 
                },
            ]
        }),

        created () {
            this.initialize();
        },

        methods: {      

            initialize () {
                this.tipo = JSON.parse(window.localStorage.getItem("tipoSelecionado"));
                this.tipoSelecionado = this.tipo.nome;
            },    

            adicionar(){     
                let objEnvio = {
                    pergunta: this.pergunta,
                    tipo: this.tipo._id,
                    ativo: true
                }
                http.post("questions/add", objEnvio)
                    .then((response) => {                    
                        if(!response.data.status) {
                            store.dispatch("Notification/alert", {
                                type: "warning",
                                message: response.data.message,
                                timeClose: 3000
                            });
                        } else {                            
                            store.dispatch("Notification/alert", {
                                type: "info",
                                message: "Dados adicionado com sucesso",
                                timeClose: 3000
                            });   
                            this.pergunta = "";     
                            this.buscarQuestoes();                                                     
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });     
            },

            buscarQuestoes(){
                let url = "questions/getByType/" + this.tipo._id;
                http.get(url)
                .then((response) => {                    
                        if(!response.data.status) {
                            store.dispatch("Notification/alert", {
                                type: "warning",
                                message: response.data.message,
                                timeClose: 3000
                            });
                        } else {                   
                            this.listaPerguntas = response.data.Response;                           
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });   
            },

            voltar(){
                this.$router.push("/perguntas/personalizados");
            },

            excluir(id){
               let url = "questions/remove/" + id;
                http.get(url)
                    .then((response) => {                    
                            if(!response.data.status) {
                                store.dispatch("Notification/alert", {
                                    type: "warning",
                                    message: response.data.message,
                                    timeClose: 3000
                                });
                            } else {                            
                                this.buscarQuestoes();                          
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            store.dispatch("Notification/alert", {
                                type: "error",
                                message: "Ocorreu um erro",
                                timeClose: 3000
                            });
                        });   
            }

        },
    }
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardContent .container {
        padding: 0;
    }
    .rowButton {
        text-align: center;
    }
</style>