<template>  
  <v-flex wrap xs12 sm12 md12 lg6 align-center class="d-flex cardRight"> 
    <v-flex xs12 sm12 md12 lg6>
      <v-card class="cardItem" elevation="5">

        <h2 class="text-center titleLogin">ESQUECI A SENHA</h2>

        <v-flex xs12 sm12 md12 lg12>
              <v-flex xs12 sm12 md12 lg10 class="mt-0 mb-0 ml-auto mr-auto">
                  <v-text-field 
                    solo
                    label="E-mail"
                    required 
                    v-model="email"
                    light
                  ></v-text-field>        
            </v-flex>
        </v-flex>     

        <v-flex xs12 sm12 md12 lg12>
              <v-flex xs12 sm12 md12 lg6 class="mt-0 mb-0 ml-auto mr-auto">
                  <v-btn 
                    block 
                    color="#ea6936" 
                    dark 
                    v-on:click="enviarSenha"
                  >Enviar</v-btn>
            </v-flex>
        </v-flex>

        <v-flex xs12 sm12 md12 lg12>
              <v-flex xs12 sm12 md12 lg5 class="mt-5 mb-0 ml-auto mr-auto text-center">
                  <router-link to="/" class="black--text text-link">Voltar</router-link>               
            </v-flex>
        </v-flex>

      </v-card>
    </v-flex>
  </v-flex>
</template>

<script>

  import store from "@/_store";

  export default {
    data: () => ({   
      email: "",
      baseURL: process.env.VUE_APP_API,
    }),
    methods: {
      enviarSenha: function() {
        store.dispatch("Notification/alert", {
          type: "info",
          message: "Funcionalidade não implementada",
          timeClose: 3000
        }); 
      }
    }
  }
</script>

<style lang="scss" scoped> 
    .cardRight {
      background: #fdfdfd;
      height: 100vh;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      @media only screen and (max-width: 992px) {
        padding: 0 20px;
      }
    }
    .text-link {
      text-decoration: none;
      font-size: 15px;
    }
    .titleLogin {
        margin: 0 20px 40px 20px;
        color: #ea6936;
        border-radius: 5px;
        padding: 5px;
    }
    .cardItem {
      padding: 40px 0;
    }
    @media only screen and (max-width: 992px) {
      .cardItem {
        padding: 40px;
      }
    }
</style>