<template>
    <v-app>

        <Alert /> 
    
        <div class="app"> 

            <SideMenu /> 

            <Header />
        
            <v-main class="content">        
                <div class="cardContent">
                    <v-card class="cardItem">
                        <form>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Editar Hidrante</h3>
                                </v-col>
                            </v-row>  

                            <v-row>
                                <v-col cols="12" md="3">
                                    <v-select
                                        :items="['Ativo', 'Inativo']"
                                        v-model="status"
                                        label="Status"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>                                
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-select
                                        :items="listaEmpresa"
                                        v-model="empresaSelecionada"
                                        label="Empresa"
                                        item-text="nome"
                                        item-value="_id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>
                            </v-row>                          

                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-select
                                        :items="listaEquipamentos"
                                        v-model="equipamento"
                                        label="Local Equipamento"
                                        item-text="nome"
                                        item-value="_id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <v-row>                                
                                <v-col cols="12" md="3">
                                    <v-text-field
                                        v-model="numeroPatrimonio "
                                        label="Número de Patrimônio"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>   
                                <v-col cols="12" md="3">
                                    <v-text-field
                                        v-model="tipo"
                                        label="Tipo"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>  
                                <v-col cols="12" md="3">
                                    <v-select
                                        :items="mangueiras"
                                        v-model="mangueira"
                                        label="Mangueira"
                                        item-text="value"
                                        item-value="id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>          
                                <v-col cols="12" md="3">
                                    <v-text-field
                                        v-model="quantidadeMangueiras"
                                        label="Qtd. de Mangueira"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>                                        
                            </v-row>   

                            <v-row>                                
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="ultimoTesteHidroStatico"
                                        label="Mês/Ano do último teste hidrostático mangueira"
                                        required
                                        solo
                                        light
                                        v-mask="'##/####'"
                                        v-on:blur="setProximoTeste(`${ultimoTesteHidroStatico}`)"
                                    ></v-text-field>
                                </v-col>  
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="proximoTesteHidroStatico"
                                        label="Mês/Ano do próximo teste hidrostático mangueira"
                                        required
                                        solo
                                        light
                                        disabled
                                    ></v-text-field>
                                </v-col>   
                            </v-row>

                            <v-row>                                
                                <v-col cols="12" md="3">
                                    <v-select
                                        :items="listaEsguichosJatoSolido"
                                        v-model="esguichosJatoSolido"
                                        label="Esguichos jato sólido"
                                        item-text="value"
                                        item-value="id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>   
                                <v-col cols="12" md="3">
                                    <v-text-field
                                        v-model="qtdEsguichosJatoSolido"
                                        label="Qtd. esguichos jato sólido"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>   
                                <v-col cols="12" md="3">
                                    <v-select
                                        :items="listaEsguichosRegulaveis"
                                        v-model="esguichosRegulaveis"
                                        label="Esguichos reguláveis"
                                        item-text="value"
                                        item-value="id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>   
                                <v-col cols="12" md="3">
                                    <v-text-field
                                        v-model="qtdEsguichosRegulaveis"
                                        label="Qtd. esguichos reguláveis"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>   
                            </v-row>

                            <v-row>                                
                                <v-col cols="12" md="3">
                                    <v-select
                                        :items="listaChaveStorz"
                                        v-model="chaveStorz"
                                        label="Chave storz"
                                        item-text="value"
                                        item-value="id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>   
                                <v-col cols="12" md="3">
                                    <v-text-field
                                        v-model="qtdChaveStorz"
                                        label="Qtd. Chave storz"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>   
                                <v-col cols="12" md="3">
                                    <v-select
                                        :items="listaAdaptador"
                                        v-model="adaptador"
                                        item-text="value"
                                        item-value="id"
                                        label="Adaptador"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>   
                                <v-col cols="12" md="3">
                                    <v-text-field
                                        v-model="qtdAdaptador"
                                        label="Qtd. Adaptador"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>   
                            </v-row>
                            
                            <v-row>                                
                                <v-col cols="12" md="3">
                                    <v-select
                                        :items="listaTampao"
                                        v-model="tampao"
                                        label="Tampão"
                                        item-text="value"
                                        item-value="id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>   
                                <v-col cols="12" md="3">
                                    <v-text-field
                                        v-model="qtdTampao"
                                        label="Qtd. Tampão"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>   
                                <v-col cols="12" md="3">
                                    <v-select
                                        :items="listaRegistroGlobo"
                                        v-model="registroGlobo"
                                        label="Registro globo"
                                        item-text="value"
                                        item-value="id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>   
                                <v-col cols="12" md="3">
                                    <v-text-field
                                        v-model="qtdRegistroGlobo"
                                        label="Qtd. Registro globo"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>   
                            </v-row>
                           
                            <v-row>       
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="inspecaoMensal"
                                        label="Próxima inspeção mensal"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>                           
                                <v-col cols="12" md="3">
                                    <v-select
                                        :items="[
                                            'Ativo', 
                                            'Vencido', 
                                            'Manutenção', 
                                            'Inativo', 
                                            'Condenado'
                                        ]"
                                        v-model="situacao"
                                        label="Situação"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>                                    
                            </v-row>
                           
                            <v-row class="rowButton">
                                <v-col cols="12" md="12">
                                    <v-btn
                                        small                                            
                                        color="#ea6936" 
                                        dark 
                                        v-on:click="salvarDados"
                                    >
                                        Salvar Dados
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </form>
                    </v-card>
                </div>
            </v-main>
        </div>   
    
    </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';
    import http from '@/_services/http';
    import store from "@/_store";
    import {mask} from 'vue-the-mask';
    import moment from 'moment';

    export default {
        components: {
            Alert,
            Header,
            SideMenu
        },
        directives: {
            mask
        },
        data () {
            return {   
                idHidrante: "",
                status: "",
                listaEmpresa: [],
                empresaSelecionada: "",
                listaEquipamentos: [],
                equipamento: "",
                numeroPatrimonio: "",
                tipo: "",
                mangueiras: [
                    {
                        "id": 1,
                        "value": "Tipo 1 - 1 ½ 15 mts"
                    }, {
                        "id": 2,
                        "value": "Tipo 1 - 1 ½ 20 mts"
                    }, {
                        "id": 3,
                        "value": "Tipo 1 - 1 ½ 25 mts"
                    }, {
                        "id": 4,
                        "value": "Tipo 1 - 1 ½ 30 mts"
                    }, {
                        "id": 5,
                        "value": "Tipo 2 - 1 ½ 15 mts"
                    }, {
                        "id": 6,
                        "value": "Tipo 2 - 1 ½ 20 mts"
                    }, {
                        "id": 7,
                        "value": "Tipo 2 - 1 ½ 25 mts"
                    }, {
                        "id": 8,
                        "value": "Tipo 2 - 1 ½ 30 mts"
                    }, {
                        "id": 9,
                        "value": "Tipo 2 - 2 ½ 15 mts"
                    }, {
                        "id": 10,
                        "value": "Tipo 2 - 2 ½ 20 mts"
                    }, {
                        "id": 11,
                        "value": "Tipo 2 - 2 ½ 25 mts"
                    }, {
                        "id": 12,
                        "value": "Tipo 2 - 2 ½ 30 mts"
                    }, {
                        "id": 13,
                        "value": "Tipo 3 - 2 ½ 15 mts"
                    }, {
                        "id": 14,
                        "value": "Tipo 3 - 2 ½ 20 mts"
                    }, {
                        "id": 15,
                        "value": "Tipo 3 - 2 ½ 25 mts"
                    }, {
                        "id": 16,
                        "value": "Tipo 3 - 2 ½ 30 mts"
                    }, {
                        "id": 17,
                        "value": "Tipo 4 - 2 ½ 15 mts"
                    }, {
                        "id": 18,
                        "value": "Tipo 4 - 2 ½ 20 mts"
                    }, {
                        "id": 19,
                        "value": "Tipo 4 - 2 ½ 25 mts"
                    }, {
                        "id": 20,
                        "value": "Tipo 4 - 2 ½ 30 mts"
                    }, {
                        "id": 21,
                        "value": "Tipo 5 - 2 ½ 15 mts"
                    }, {
                        "id": 22,
                        "value": "Tipo 5 - 2 ½ 20 mts"
                    }, {
                        "id": 23,
                        "value": "Tipo 5 - 2 ½ 25 mts"
                    }, {
                        "id": 24,
                        "value": "Tipo 5 - 2 ½ 30 mts"
                    }
                ],
                mangueira: "",
                quantidadeMangueiras: "",
                ultimoTesteHidroStatico: "",
                proximoTesteHidroStatico: "",
                listaEsguichosJatoSolido: [
                    {
                        "id": 1,
                        "value": "1 ½ 13 mm"
                    }, {
                        "id": 2,
                        "value": "1 ½ 16 mm"
                    }, {
                        "id": 3,
                        "value": "1 ½ 19 mm"
                    }, {
                        "id": 4,
                        "value": "2 ½ 16 mm"
                    }, {
                        "id": 5,
                        "value": "2 ½ 19 mm"
                    }, {
                        "id": 6,
                        "value": "2 ½ 25 mm"
                    }
                ],
                esguichosJatoSolido: "",
                qtdEsguichosJatoSolido: "",
                listaEsguichosRegulaveis: [
                    {
                        "id": 1,
                        "value": "1 ½ 38 MM"
                    }, {
                        "id": 2,
                        "value": "2 ½ 63 MM"
                    }
                ],
                esguichosRegulaveis: "",
                qtdEsguichosRegulaveis: "",
                listaChaveStorz: [
                    {
                        "id": 1,
                        "value": "1 ½"
                    }, {
                        "id": 2,
                        "value": "2 ½"
                    }
                ],
                chaveStorz: "",
                qtdChaveStorz: "",
                listaAdaptador: [
                    {
                        "id": 1,
                        "value": "STORZ 1 ½ X 2 ½ ROSCA"
                    }, {
                        "id": 2,
                        "value": "STORZ 2 ½ X 1 ½ ROSCA"
                    }, {
                        "id": 3,
                        "value": "STORZ 2 ½ X 2 ½ ROSCA"
                    }
                ],
                adaptador: "",
                qtdAdaptador: "",
                listaTampao: [
                    {
                        "id": 1,
                        "value": "1 ½ CORRENTE"
                    }, {
                        "id": 2,
                        "value": "2 ½ CORRENTE"
                    }
                ],
                tampao: "",
                qtdTampao: "",
                listaRegistroGlobo: [{
                    "id": 1,
                    "value": "2 ½ 45 GRAUS"
                }],
                registroGlobo: "",
                qtdRegistroGlobo: "",
                inspecaoMensal: "",
                situacao: ""
            }
        },
        created () {
            this.initialize();
        },
        methods: {
            initialize () {
                http.get("locals/getAll")
                    .then((response) => {
                        this.listaEquipamentos = response.data.Response;
                        this.buscarEmpresa();
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            buscarEmpresa(){
                http.get("companies/getAll")
                    .then((response) => {
                        this.listaEmpresa = response.data.Response.filter(item => {
                            return item.ativo == true;
                        });
                        this.buscarhidrante();
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            buscarhidrante(){
                let idHidrante = window.location.pathname;
                idHidrante = idHidrante.split("/");
                idHidrante = idHidrante[3];
                let url = "hydrants/get/" + idHidrante;                
                http.get(url)
                    .then((response) => {
                        let base = response.data.Response;   
                        this.idHidrante = base._id;                     
                        if(base.ativo){
                            this.status = "Ativo";
                        } else {
                            this.status = "Inativo";
                        }
                        this.empresaSelecionada = base.empresa;
                        this.equipamento = base.local;
                        this.numeroPatrimonio = base.numero_patrimonio;
                        this.tipo = base.tipo;
                        this.mangueira = base.mangueira;
                        this.quantidadeMangueiras = base.qntd_mangueiras;
                        this.ultimoTesteHidroStatico = base.ultimo_teste;
                        this.proximoTesteHidroStatico = base.proximo_teste;
                        this.esguichosJatoSolido = base.esguichos_jato_solido;
                        this.qtdEsguichosJatoSolido = base.quantidade_jatos;
                        this.esguichosRegulaveis = base.esguichos_regulaveis;
                        this.qtdEsguichosRegulaveis = base.quantidade_regulaveis;
                        this.chaveStorz = base.chave_storz;
                        this.qtdChaveStorz = base.quantidade_storz;
                        this.adaptador = base.adaptador;
                        this.qtdAdaptador = base.quantidade_adaptador;
                        this.tampao = base.tampao;
                        this.qtdTampao = base.quantidade_tampao;
                        this.registroGlobo = base.registro_globo;
                        this.qtdRegistroGlobo = base.quantidade_globo;
                        this.inspecaoMensal = base.proxima_inspecao;
                        this.situacao = base.Status;
                        if(base.ativo){
                            this.status = "Ativo";
                        } else {
                            this.status = "Inativo";
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            setProximoTeste(e){
                let startdate = "01/" + e;
                let new_date = moment(startdate, "DD/MM/YYYY").add(365, 'days');
                this.proximoTesteHidroStatico = new_date.format("MM/YYYY");                 
            },

            salvarDados(){
                let objEnvio = {
                    "empresa": this.empresaSelecionada,
                    "local": this.equipamento,
                    "inspecao": null,
                    "numero_patrimonio": this.numeroPatrimonio,
                    "tipo": this.tipo,
                    "mangueira": this.mangueira,
                    "qntd_mangueiras": this.quantidadeMangueiras,
                    "ultimo_teste": this.ultimoTesteHidroStatico,
                    "proximo_teste": this.proximoTesteHidroStatico,
                    "esguichos_jato_solido": this.esguichosJatoSolido,
                    "quantidade_jatos": this.qtdEsguichosJatoSolido,
                    "esguichos_regulaveis": this.esguichosRegulaveis,
                    "quantidade_regulaveis": this.qtdEsguichosRegulaveis,
                    "chave_storz": this.chaveStorz,
                    "quantidade_storz": this.qtdChaveStorz,
                    "adaptador": this.adaptador,
                    "quantidade_adaptador": this.qtdAdaptador,
                    "tampao": this.tampao,
                    "quantidade_tampao": this.qtdTampao,
                    "registro_globo": this.registroGlobo,
                    "quantidade_globo": this.qtdRegistroGlobo,
                    "proxima_inspecao": this.inspecaoMensal,
                    "Status": this.situacao,
                    "ativo": true
                }
                console.log(objEnvio);
                let url = "hydrants/edit/" + this.idHidrante;
                http.post(url, objEnvio).then((response) => {
                    if(!response.data.status) {
                        store.dispatch("Notification/alert", {
                            type: "warning",
                            message: response.data.message,
                            timeClose: 3000
                        });
                    } else {                            
                        store.dispatch("Notification/alert", {
                            type: "info",
                            message: "Dados salvos com sucesso",
                            timeClose: 3000
                        });       
                        setTimeout(function(){
                            window.location.href = "/hidrantes"; 
                        }, 2000);                              
                    }
                }).catch((error) => {
                    console.log(error);
                    store.dispatch("Notification/alert", {
                        type: "error",
                        message: "Ocorreu um erro",
                        timeClose: 3000
                    });
                });
            }
        }
  }  
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardItem {
        float: left;
        display: block;
        height: 100%;
    }
</style>