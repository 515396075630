<template>
  <v-app>
    
    <div class="app"> 

      <SideMenu /> 

      <Header />
     
      <v-main class="content">        
        <div class="cardContent">

          <v-tabs v-model="tab" background-color="transparent" color="transparent">
            <v-tab>Principal</v-tab>
            <v-tab>Estatísticas</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" background-color="#f0f0f0">
            
            <v-tab-item>
              <v-img       
                :src="require('../../images/anuncios/anuncio1.png')" 
                class="logoImage"
                contain
              ></v-img>
            </v-tab-item>

            <v-tab-item>
              <Dashboard />                
            </v-tab-item>

          </v-tabs-items>
        
        </div>
      </v-main>
    
    </div>   
  
  </v-app>
</template>

<script>
  import Header from '@/_components/Header';
  import SideMenu from '@/_components/SideMenu';
  import Dashboard from '@/_components/private/home/Dashboard';

  export default {
    components: {
      Header,
      SideMenu,
      Dashboard
    },
    data () {
      return {
        tab: null
      }
    },
  }  
</script>

<style scoped lang="scss">  
  .v-window.v-item-group.theme--light.v-tabs-items {
    padding: 20px 0;
  }
  .v-tab {
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    margin-right: 3px;
    border: 1px solid #CCC;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    font-weight: 600;
    background: #ea6936;
    color: white !important;
  }
  .v-tab.v-tab--active {
    background: #f0f0f0;
    color: #ea6936 !important;
  }
  .v-tab:before {
    display: none !important;
  }
  .v-window.v-item-group.theme--light.v-tabs-items {
    box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 10px 5px 0 rgba(0, 0, 0, 0.12);
    border: 1px solid #CCC;
    border-top: none;
    padding: 20px !important;
    background: #f0f0f0;
  }  
</style>