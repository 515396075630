<template>
    <v-app>

        <Alert />
    
        <div class="app"> 

            <SideMenu /> 

            <Header />
     
            <v-main class="content">        
                <div class="cardContent">
                    <v-card class="cardItem">
                        
                        <form>
                            
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <h3>Perguntas dos Hidrantes</h3>
                                </v-col>                                
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">1 - Quantidade e modelo de mangueiras de incêndio conforme projeto de incêndio e pânico</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">2 - Etiquetas e prazo de manutenção e teste hidrostático de mangueiras de incêndio dentro da validade conforme NBR 12779</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">3 - Mangueiras de incêndio sem danos, amassados ou corrosões</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">4 - Quantidade e modelo de Esguichos, Proporcionadores sólidos ou reguláveis conforme projeto de incêndio e pânico</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>
                            
                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">5 - Quantidade e modelo de Chave Storz conforme projeto de incêndio e pânico</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">6 - Quantidade e modelo de Adaptadores e Tampão Storz conforme projeto de incêndio e pânico</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">7 - Quantidade e modelo de Registro Globo conforme projeto de incêndio e pânico</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">8 - Registro Globo sem danos, vazamentos, amassados ou corrosões</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">9 - Instalação de abrigo de hidrante adequada (máx.: 1,20m)</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>
                                                                
                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">10 - Abrigo de Hidrante sem danos, amassados ou corrosões</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">11 - Abrigo de Hidrante desobstruído, acesso liberado</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">12 - Sinalização visível e dentro da norma vigente</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">13 - Lacre do abrigo de hidrante íntegro</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>                           

                        </form>

                    </v-card>
                </div>
            </v-main>
    
        </div>   
  
    </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';

    export default {
        components: {
            Alert,
            Header,
            SideMenu
        }
    }
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardContent .container {
        padding: 0;
    }
    .cardTabPerfil {
        padding: 20px;
    }
    .v-tab.v-tab {
        font-weight: bold;
    }
    .v-window.v-item-group.theme--light.v-tabs-items {
        padding: 40px 5px 0px 5px;
    }
    .rowButton {
        text-align: center;
    }
</style>