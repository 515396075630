<template>
    <v-app>

        <Alert />
    
        <div class="app"> 

            <SideMenu /> 

            <Header />
     
            <v-main class="content">        
                <div class="cardContent">
                    <v-card class="cardItem">
                        
                        <form>
                            
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <h3>Perguntas dos Extintores</h3>
                                </v-col>                                
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">1 - Localização, classe e modelo de extintores conforme projeto de incêndio e pânico</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">2 - Suporte e altura de instalação adequada (máx.: 1,60m)</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">3 - Equipamento desobstruído</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">4 - Sinalização de parede visível e dentro norma vigente</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>
                            
                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">5 - Sinalização de piso visível e dentro da norma vigente</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">6 - Aspecto externo sem danos, amassados ou corrosões</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">7 - Lacre íntegro</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">8 - Selo Inmetro e etiquetas de validade e manutenção íntegros e legíveis</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">9 - Prazo de manutenção e teste hidrostático dentro da validade</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>
                                                                
                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">10 - Prazo de pesagem semestral de CO² dentro da validade</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">
                                        11 - Indicador de pressão na faixa de operação<br />
                                        Não aplicáveis para modelos de extintores CO² e manômetro indireto (AMPOLA, PQSI BC, PQSI ABC, EMI e API)
                                    </p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">12 - Acessórios íntegros: mangueira, difusor, punho e válvula</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">13 - Mangueiras de descarga desobstruídas</p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <p class="ma-0">
                                        14 - Conjunto de rodagem e transporte conforme <br />
                                Aplicável apenas para modelos de extintores PQS-BC ou PQS-ABC com 20kg ou mais, CO² com 10kg ou mais, AP ou EM com 50L ou mais.
                                    </p>                                   
                                </v-col>    
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" sm="6" class="pt-0">
                                    <v-checkbox
                                        label="Não Conforme"
                                        disabled
                                    ></v-checkbox>
                                </v-col> 
                            </v-row>

                        </form>

                    </v-card>
                </div>
            </v-main>
    
        </div>   
  
    </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';

    export default {
        components: {
            Alert,
            Header,
            SideMenu
        }
    }
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardContent .container {
        padding: 0;
    }
    .cardTabPerfil {
        padding: 20px;
    }
    .v-tab.v-tab {
        font-weight: bold;
    }
    .v-window.v-item-group.theme--light.v-tabs-items {
        padding: 40px 5px 0px 5px;
    }
    .rowButton {
        text-align: center;
    }
</style>