var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Alert'),_c('div',{staticClass:"app"},[_c('SideMenu'),_c('Header'),_c('v-main',{staticClass:"content"},[_c('div',{staticClass:"cardContent"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar Hidrantes","single-line":"","hide-details":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{attrs:{"small":"","color":"#ea6936","dark":""},on:{"click":function($event){return _vm.redirectPage('/hidrante/novo')}}},[_vm._v(" Criar Hidrante ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.base,"search":_vm.search,"sort-by":"id"},scopedSlots:_vm._u([{key:"item.local",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showNameLocal(item.local))+" ")]}},{key:"item.mangueira",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showNameMangueira(item.mangueira))+" ")]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [(item.ativo === true)?_c('div',[_vm._v(" Ativo ")]):_c('div',[_vm._v(" Inativo ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","color":"#ea6936","dark":""},on:{"click":function($event){return _vm.redirectPage('/hidrante/editar/', item._id)}}},[_vm._v(" Editar ")])]}}],null,true)})],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }