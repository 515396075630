import axios from 'axios';

export default {
  
  get(rota) {    
    return axios.get(rota);
  },
 
  post(rota, data) {  
    return axios.post(rota, data);
  },
  
};