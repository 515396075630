<template>
  <v-app>

    <Alert />
    
    <div class="app"> 

      <SideMenu /> 

      <Header />
     
      <v-main class="content">        
        <div class="cardContent">
            <v-container>

                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Pesquisar Extintores"
                            single-line
                            hide-details
                            solo
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="4">
                        <v-btn 
                            small 
                            color="#ea6936" 
                            dark
                            @click="redirectPage('/extintor/novo')"
                        >
                            Criar Extintor
                        </v-btn>
                    </v-col>
                </v-row>                
                
                <v-row>
                    <v-col cols="12" md="12">
                        <v-data-table
                            :headers="headers"
                            :items="base"
                            :search="search"
                            sort-by="id"
                            class="elevation-1"
                        >
                            <template v-slot:[`item.ativo`]="{ item }">
                                <div v-if="item.ativo === true">
                                    Ativo
                                </div>
                                <div v-else>
                                    Inativo
                                </div>
                            </template>  
                            <template v-slot:[`item.modelo`]="{ item }">
                                <div v-if="item.modelo == 1">
                                    AMPOLA-CO2 ou N2
                                </div>
                                <div v-else-if="item.modelo == 2">
                                    AP
                                </div>
                                <div v-else-if="item.modelo == 3">
                                    API
                                </div>
                                <div v-else-if="item.modelo == 4">
                                    CLASSE D
                                </div>
                                <div v-else-if="item.modelo == 5">
                                    CLASSE K
                                </div>
                                <div v-else-if="item.modelo == 6">
                                    CO2
                                </div>
                                <div v-else-if="item.modelo == 7">
                                    EM
                                </div>
                                <div v-else-if="item.modelo == 8">
                                    EMI
                                </div>
                                <div v-else-if="item.modelo == 9">
                                    FE36
                                </div>
                                <div v-else-if="item.modelo == 10">
                                    PQS ABC
                                </div>
                                <div v-else-if="item.modelo == 11">
                                    PQS-BC
                                </div>
                                <div v-else-if="item.modelo == 12">
                                    PQSI ABC
                                </div>
                                <div v-else-if="item.modelo == 13">
                                    PQSI-BC
                                </div>
                            </template>
                            <template v-slot:[`item.local`]="{ item }">
                                {{ showNameLocal(item.local) }}                                
                            </template>  
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn
                                    x-small                                            
                                    color="#ea6936" 
                                    dark 
                                    @click="redirectPage('/extintor/editar/', item._id)"
                                >
                                    Editar
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>

            </v-container>        
        </div>
      </v-main>
    
    </div>   
  
  </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';
    import http from '@/_services/http';
    import store from "@/_store";

    export default {
        components: {
            Alert,
            Header,
            SideMenu
        },

        data: () => ({
            search: '',
            listaLocal: [],
            headers: [
                {
                    text: 'Selo Inmetro',
                    align: 'start',
                    value: 'selo_inmetro', 
                    sortable: true,                     
                    filterable: true          
                },
                {
                    text: 'Modelo',
                    align: 'start',
                    value: 'modelo', 
                    sortable: true,                     
                    filterable: true          
                },
                { 
                    text: 'Local',
                    align: 'center',
                    value: 'local', 
                    sortable: true,                     
                    filterable: true   
                },
                { 
                    text: 'Status', 
                    align: 'center',
                    value: 'status', 
                    sortable: true,                     
                    filterable: true   
                },
                { 
                    text: 'Situação', 
                    align: 'center',
                    value: 'ativo', 
                    sortable: true,                     
                    filterable: true   
                },
                { 
                    text: '', 
                    value: 'actions',
                    sortable: false, 
                    filterable: false 
                },
            ],
            base: []            
        }),

        created () {
            this.initialize();
        },

        methods: {
            initialize () {
                http.get("extinguishers/getAll")
                    .then((response) => {
                        this.buscarLocais();
                        this.base = response.data.Response;
                     })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            buscarLocais(){
                http.get("locals/getAll")
                    .then((response) => {
                        this.listaLocal = response.data.Response;
                     })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            showNameLocal(idLocal){
                let localName = this.listaLocal.filter(function (value){
                    return value._id == idLocal._id;
                }).map(value2 => { 
                    return value2.nome; 
                });
                return localName[0];
            },

            redirectPage (url, id){
                let link = url + (id || "");
                window.location.href = link;
            },
        },
    }
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardContent .container {
        padding: 0;
    }
    .cardTabPerfil {
        padding: 20px;
    }
    .v-tab.v-tab {
        font-weight: bold;
    }
    .v-window.v-item-group.theme--light.v-tabs-items {
        padding: 40px 5px 0px 5px;
    }
    .rowButton {
        text-align: center;
    }
</style>