<template>
  <v-app>

    <div class="app">   
    
      <SideMenu /> 

      <Header />
      
      <v-main class="content cardContent">  
        <v-flex sm12 md12 lg12 xs12 class="pageIntern">
          <h4>
            <b>404</b>
            <br>
            Desculpe, mas a página que você procura não foi encontrada.
          </h4>       
        </v-flex>
      </v-main>
      
    </div>
  </v-app>
</template>

<script>
  import Header from '@/_components/Header';
  import SideMenu from '@/_components/SideMenu';

  export default {
    components: {
      Header,
      SideMenu
    }    
  }   
</script>

<style scoped lang="scss">
  h4 {
    padding: 0 50px;
    font-size: 28px;
    font-weight: 100;
    text-align: center;
    b {
      font-size: 82px;
      font-weight: 300;
      border-bottom: 3px solid #1A3B87;
    }
  }
</style>