<template>
    <v-app>

        <Alert />
        
        <div class="app"> 

            <SideMenu /> 

            <Header />
        
            <v-main class="content">        
                <div class="cardContent">
                    <v-card class="cardItem">

                        <v-row>
                                        <v-col cols="12" md="12">
                                            <h3>Editar Usuário</h3>
                                        </v-col>
                                    </v-row>
                                    <br>
                                    <br>

                        <v-tabs 
                            v-model="tab"
                            color="black"
                            flat
                            grow
                        >
                            <v-tab>Dados Pessoais</v-tab>
                            <v-tab>Dados de Acesso</v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">

                            <v-tab-item>
                                <form>

                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-select
                                                :items="['Ativo', 'Inativo']"
                                                v-model="status"
                                                label="Status"
                                                required
                                                solo
                                                light
                                            ></v-select>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <v-text-field
                                                v-model="nomeCompleto"
                                                label="Nome Completo"
                                                required
                                                solo
                                                light
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" md="12" lg="4">
                                            <v-text-field
                                                v-model="email"
                                                label="E-mail"
                                                required
                                                solo
                                                light
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12" lg="4">
                                            <v-text-field
                                                v-model="cpf"
                                                label="CPF"
                                                required
                                                solo
                                                light
                                                v-mask="'###.###.###-##'"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12" lg="4">
                                            <v-select
                                                :items="['Administrador', 'Inspetor', 'Empresa']"
                                                v-model="tipo"
                                                label="Tipo"
                                                required
                                                solo
                                                light
                                            ></v-select>
                                        </v-col>                        
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <v-combobox
                                                v-model="listaEmpresasSelecionadas"
                                                :filter="filter"
                                                :hide-no-data="!search"
                                                :items="listaEmpresas"
                                                :search-input.sync="search"
                                                hide-selected
                                                label="Empresas"
                                                item-text="nome"
                                                item-value="_id"
                                                multiple
                                                small-chips
                                                solo
                                            >
                                                <template v-slot:no-data>
                                                    <v-list-item>
                                                        <span class="subheading">Não encontrada </span>
                                                        <v-chip                                                    
                                                            label
                                                            small
                                                        >
                                                            {{ search }}
                                                        </v-chip>
                                                    </v-list-item>
                                                </template>
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                                    <v-chip
                                                        v-if="item === Object(item)"
                                                        v-bind="attrs"
                                                        :input-value="selected"
                                                        label
                                                        small
                                                    >
                                                        <span class="pr-2">
                                                            {{ item.nome }}
                                                        </span>
                                                        <v-icon
                                                            small
                                                            @click="parent.selectItem(item)"
                                                        >
                                                            $delete
                                                        </v-icon>
                                                    </v-chip>
                                                </template>                                        
                                            </v-combobox>
                                        </v-col>
                                    </v-row>

                                </form>
                            </v-tab-item>

                            <v-tab-item>
                                        <form>
                                            <v-row>
                                                <v-col cols="12" md="12" lg="6">
                                                    <v-text-field
                                                        v-model="username"
                                                        label="Usuario"
                                                        required
                                                        solo
                                                        light
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="12" lg="6">
                                                    <v-text-field
                                                        v-model="senha"
                                                        label="Nova Senha"
                                                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :type="showPass ? 'text' : 'password'"
                                                        :rules="[rules.required, rules.min]"
                                                        solo
                                                        light
                                                        @click:append="showPass = !showPass"
                                                    ></v-text-field>
                                                </v-col>                     
                                            </v-row>
                                        </form>
                            </v-tab-item>
                    
                        </v-tabs-items>

                        <v-row class="rowButton">
                            <v-col cols="12" md="12">
                                <v-btn
                                    small                                            
                                    color="#ea6936" 
                                    dark 
                                    v-on:click="salvarDados"
                                >
                                    Salvar Dados
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-card>
                </div>
            </v-main>
        </div>   
    
    </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';
    import {mask} from 'vue-the-mask';
    import http from '@/_services/http';
    import store from "@/_store";

  export default {
    components: {
        Alert,
        Header,
        SideMenu
    },
    directives: {
        mask
    },
    data () {
        return {
            id: "",
            nomeCompleto: "",
            email: "",
            cpf: "",
            tipo: "",
            status: "",
            listaEmpresasSelecionadas: [],
            listaEmpresas: [],
            search: null,
            username: "",
            senha: "",
            tab: null,
            showPass: false,
            senhaRetorno: "",
            rules: {
                required: value => !!value || 'Obrigatório.',
                min: v => v.length >= 6 || 'Minimo 6 caracteres'
            },
        }
    },
    created () {
        this.initialize();
    },
    methods: {
        initialize() {
            let idUser = window.location.pathname;
            idUser = idUser.split("/");
            idUser = idUser[3];
            let url = "users/get/" + idUser;
            http.get(url)
                .then((response) => {
                    let base = response.data.Response[0];
                    this.id = base._id;
                    this.nomeCompleto = base.nome;
                    this.email = base.email;
                    this.cpf = base.cpf;
                    this.items = base.empresas;
                    this.username = base.username;
                    this.senhaRetorno = base.senha;
                    if(base.ativo){
                        this.status = "Ativo";
                    } else {
                        this.status = "Inativo";
                    }
                    if(base.permissao === 0){
                        this.tipo = "Administrador";
                    } else if(base.permissao === 1){
                        this.tipo = "Inspetor";
                    } else if(base.permissao === 2){
                        this.tipo = "Empresa";
                    }
                    this.buscarEmpresas(base.empresas);
                })
                .catch((error) => {
                    console.log(error);
                    store.dispatch("Notification/alert", {
                        type: "error",
                        message: "Ocorreu um erro",
                        timeClose: 3000
                    });
                });
        },

        buscarEmpresas(baseUser){
            http.get("companies/getAll")
                .then((response) => {                    
                    this.listaEmpresas = response.data.Response.filter(item => {
                        return item.ativo == true;
                    });
                    this.listaEmpresasSelecionadas = baseUser;
                })
                .catch((error) => {
                    console.log(error);
                    store.dispatch("Notification/alert", {
                        type: "error",
                        message: "Ocorreu um erro",
                        timeClose: 3000
                    });
                });
        },

        filter (item, queryText, itemText) {
            if (item.header) return false
            const hasValue = val => val != null ? val : ''
            const text = hasValue(itemText)
            const query = hasValue(queryText)
            return text.toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1
        },

        salvarDados(){
            let ativo = false;
            if(this.status == "Ativo"){
                ativo = true;
            }
            let permissao = null;
            if(this.tipo == "Administrador"){
                permissao = 0;
            } else if(this.tipo == "Inspetor"){
                permissao = 1;
            } else if(this.tipo == "Empresa"){
                permissao = 2
            }
            let senha = "";
            if(this.senha == ""){
                senha = this.senhaRetorno;
            } else {
                senha = this.senha;
            }

            let objEnvio = {
                nome: this.nomeCompleto,
                cpf: this.cpf,
                email: this.email,
                empresas: this.listaEmpresasSelecionadas,
                username: this.username,
                senha: senha,
                permissao: permissao,
                ativo: ativo,
                reset_senha: false
            }
            let url = "users/edit/" + this.id;
            http.post(url, objEnvio).then((response) => {
                if(!response.data.status) {
                    store.dispatch("Notification/alert", {
                        type: "warning",
                        message: response.data.message,
                        timeClose: 3000
                    });
                } else {                            
                    store.dispatch("Notification/alert", {
                        type: "info",
                        message: "Dados alterados com sucesso",
                        timeClose: 3000
                    });       
                    setTimeout(function(){
                        window.location.href = "/usuarios";  
                    }, 2000);                              
                }
            }).catch((error) => {
                console.log(error);
                store.dispatch("Notification/alert", {
                    type: "error",
                    message: "Ocorreu um erro",
                    timeClose: 3000
                });
            });
        }

    },
  }  
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardItem {
        float: left;
        display: block;
        height: 100%;
    }
    .v-tab.v-tab {
        font-weight: bold;
    }
    .v-window.v-item-group.theme--light.v-tabs-items {
        padding: 40px 5px 0px 5px;
    }   
</style>