var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Alert'),_c('div',{staticClass:"app"},[_c('SideMenu'),_c('Header'),_c('v-main',{staticClass:"content"},[_c('div',{staticClass:"cardContent"},[_c('v-container',[_c('v-card',{staticClass:"cardItem"},[_c('form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h3',[_vm._v("Relatórios de Hidrantes")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-select',{attrs:{"items":['Inspecionados', 'Não Inspecionados', 'Vencimento Teste Hidrostático', 'Ocorrências', 'Status'],"label":"Tipo","required":"","solo":"","light":""},model:{value:(_vm.tipoRelatorio),callback:function ($$v) {_vm.tipoRelatorio=$$v},expression:"tipoRelatorio"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":[
                                      'Todos',
                                      'Janeiro', 
                                      'Fevereiro', 
                                      'Março', 
                                      'Abril', 
                                      'Maio',
                                      'Junho',
                                      'Julho',
                                      'Agosto',
                                      'Stembro',
                                      'Outubro',
                                      'Novembro',
                                      'Dezembro'
                                  ],"label":"Mês","required":"","solo":"","light":""},model:{value:(_vm.mes),callback:function ($$v) {_vm.mes=$$v},expression:"mes"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":[
                                      'Todos',
                                      '2021', 
                                      '2022', 
                                      '2023'
                                  ],"label":"Ano","required":"","solo":"","light":""},model:{value:(_vm.ano),callback:function ($$v) {_vm.ano=$$v},expression:"ano"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Número do patrimônio","required":"","solo":"","light":""},model:{value:(_vm.dataUltimaRecarga),callback:function ($$v) {_vm.dataUltimaRecarga=$$v},expression:"dataUltimaRecarga"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":[
                                      'Ativo', 
                                      'Vencido', 
                                      'Manutenção', 
                                      'Inativo', 
                                      'Condenado'
                                  ],"label":"Status","required":"","solo":"","light":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.listaLocal,"label":"Local","item-text":"nome","item-value":"_id","required":"","solo":"","light":""},model:{value:(_vm.local),callback:function ($$v) {_vm.local=$$v},expression:"local"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.listaEmpresa,"label":"Empresa","item-text":"nome","item-value":"_id","required":"","solo":"","light":""},model:{value:(_vm.empresa),callback:function ($$v) {_vm.empresa=$$v},expression:"empresa"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.listaUsuarios,"item-text":"nome","item-value":"_id","label":"Inspetor","required":"","solo":"","light":""},model:{value:(_vm.usuario),callback:function ($$v) {_vm.usuario=$$v},expression:"usuario"}})],1)],1),_c('v-row',{staticClass:"rowButton"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","color":"#ea6936","dark":""}},[_vm._v(" Gerar Relatório PDF ")]),_c('v-btn',{attrs:{"small":"","color":"#ea6936","dark":""}},[_vm._v(" Gerar Relatório Excel ")])],1)],1)],1)])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }