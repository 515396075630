<template>
    <v-navigation-drawer 
        floating
        permanent         
        class="float-left sideMenu"
        dark
        color="#222"
    >

        <div class="backLogo">
            <v-img 
                max-height="45"        
                :src="require('../assets/logo.png')" 
                class="logoImage"
                contain
            ></v-img>
        </div>

        <v-list 
            dense 
            dark
            color="#222"
            class="pa-0"             
            v-for="(item,index) of itemsMenu" :key="index"
        >        
            <v-list-group class="itemSubGroup" v-if="item.subItens.length > 0" v-show="item.permission.includes(isUser)">                    
                <template v-slot:activator>                        
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>
                <v-list-item :disabled="item.disabled" v-for="(sub, indx) of item.subItens" :key="indx" :to="sub.path == '#' ? '' : sub.path">
                    <span class="lineSubItem"></span>
                    <v-list-item-title v-if="sub.disabled == true" class="title-sub disabled">{{sub.title}}</v-list-item-title>                           
                    <v-list-item-title v-else class="title-sub">{{sub.title}}</v-list-item-title>                        
                </v-list-item>
            </v-list-group>                
            <v-list-item v-else :to="item.path == '#' ? '' : item.path" :disabled="item.disabled" v-show="item.permission.includes(isUser)">
                <v-list-item-title class="sideMenu-item-simple">{{ item.title }}</v-list-item-title>
            </v-list-item>
        </v-list>  
        
        <v-btn
            block
            color="#ea6936"
            small
            text
            tile
            @click="logout"
        >
            Sair
            <v-icon
                left
                dark
                class="ml-1"
            >
                mdi-logout
            </v-icon>
        </v-btn>

        <v-divider></v-divider>

        <v-flex xs12 sm12 md12 lg12 pa-4 center class="blockCompany" v-show="isUser != 0 && totalEmpresas > 0">   
            <p class="textFirstLine white--text">Você está acessando a empresa:</p>         
            <v-img 
                max-height="25"        
                :src="linkImagem" 
                class="logoImage"
                contain
            ></v-img>
            <p class="textSecondLine white--text">{{ nomeEmpresaAcesso }}</p>    
            <v-btn 
                x-small
                color="#ea6936" 
                dark 
                v-on:click="modalAlterarEmpresa"
                v-show="isUser != 0 && totalEmpresas > 1"
            >Trocar</v-btn>
        </v-flex>

        <v-dialog v-model="dialog" width="500px">            
            <v-card>
                <v-card-title>
                    <span class="text-h5">Lista de Empresas</span>
                </v-card-title>
                <v-card-text>
                    <v-radio-group v-model="radioSelect">
                        <v-radio
                            v-for="(n, idx) in empresasAcessadas" 
                            :key="idx._id"
                            :label="n.nome"
                            :value="n._id"
                            name="radioSelect"
                            color="#ea6936"
                        ></v-radio>
                    </v-radio-group>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="#ea6936"
                        text
                        @click="dialog = false"
                    >
                        Fechar
                    </v-btn>
                    <v-btn
                        color="#ea6936"
                        text
                        @click="alterarEmpresa"
                    >
                        Trocar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-navigation-drawer>
</template>

<script>
    export default {
        data: () => ({
            mini: false,
            itemsMenu: [
                {
                    title: 'Painel',
                    path: '/painel',
                    subItens: [],
                    disabled: false,
                    permission: [0, 1, 2]
                },
                {
                    title: 'Perfil',
                    path: '/perfil',
                    disabled: false,
                    subItens: [],
                    permission: [0, 1, 2]
                },                
                {
                    title: 'Usuários',
                    path: '',
                    disabled: false,
                    subItens: [
                        {                    
                            title: 'Ver Todos',
                            path: '/usuarios',
                            disabled: false
                        },
                        {                    
                            title: 'Criar Usuário',
                            path: '/usuarios/novo',
                            disabled: false
                        }                
                    ],
                    permission: [0]
                },
                {
                    title: 'Empresas',
                    path: '',
                    disabled: false,
                    subItens: [
                        {                    
                            title: 'Ver Todas',
                            path: '/empresas',
                            disabled: false
                        },
                        {                    
                            title: 'Cadastrar Empresa',
                            path: '/empresas/nova',
                            disabled: false
                        }                
                    ],
                    permission: [0]
                },
                {
                    title: 'Cadastros',
                    path: '',
                    disabled: false,
                    subItens: [
                        {                    
                            title: 'Locais de Equipamento',
                            path: '/localEquipamento',
                            disabled: false
                        },
                        {                    
                            title: 'Tipos',
                            path: '/tipos',
                            disabled: false
                        },  
                        {                    
                            title: 'Tipos de Ocorrências',
                            path: '/tiposOcorrencias',
                            disabled: false
                        },         
                        {                    
                            title: 'Extintores',
                            path: '/extintores',
                            disabled: false
                        },
                        {                    
                            title: 'Hidrantes',
                            path: '/hidrantes',
                            disabled: false
                        },
                        {                    
                            title: 'Itens Personalizados',
                            path: '/personalizados',
                            disabled: false
                        },                            
                    ],
                    permission: [0]
                },
                {
                    title: 'Relatórios',
                    path: '',
                    disabled: false,
                    subItens: [
                        {                    
                            title: 'Extintores',
                            path: '/relatorios/extintores',
                            disabled: false
                        },
                        {                    
                            title: 'Hidrantes',
                            path: '/relatorios/hidrantes',
                            disabled: false
                        },
                        {                    
                            title: 'Itens Personalizados',
                            path: '/relatorios/personalizados',
                            disabled: false
                        },  
                    ],
                    permission: [0, 1, 2]
                },                 
                {
                    title: 'Inspeções',
                    path: '',
                    disabled: false,
                    subItens: [
                        {                    
                            title: 'Extintores',
                            path: '/inspecoes/extintores',
                            disabled: false
                        },
                        {                    
                            title: 'Hidrantes',
                            path: '/inspecoes/hidrantes',
                            disabled: false
                        },
                        {                    
                            title: 'Itens Personalizados',
                            path: '/inspecoes/personalizados',
                            disabled: false
                        }              
                    ],
                    permission: [0, 1]
                },    
                {
                    title: 'Perguntas',
                    path: '',
                    disabled: false,
                    subItens: [
                        {                    
                            title: 'Extintores',
                            path: '/perguntas/extintores',
                            disabled: false
                        },
                        {                    
                            title: 'Hidrantes',
                            path: '/perguntas/hidrantes',
                            disabled: false
                        },
                        {                    
                            title: 'Personalizados',
                            path: '/perguntas/personalizados',
                            disabled: false
                        }              
                    ],
                    permission: [0]
                },      
            ],
            dialog: false,
            radioSelect: null,
            isUser: null,
            linkImagem: "",
            nomeEmpresaAcesso: "",
            empresasAcessadas: [],
            totalEmpresas: 0
        }),
        methods: {
            initValue: function(){
                let user = JSON.parse(localStorage.getItem("user"));
                let company = JSON.parse(localStorage.getItem("company"));
                if(user){
                    this.isUser = user.permissao;
                    this.totalEmpresas = user.empresas.length;   
                    this.empresasAcessadas = user.empresas.filter(item => {
                        return item._id._id !== company._id._id;
                    });
                }
                if(company){
                    this.linkImagem = company.logotipo;
                    this.nomeEmpresaAcesso = company.nome;
                    this.radioSelect = company._id;
                }
            },

            alterarEmpresa: function () {
                if(this.empresasAcessadas.length > 0){
                    let empresa = this.empresasAcessadas.filter(value => {
                        return value._id === this.radioSelect;
                    });
                    localStorage.setItem('company', JSON.stringify(empresa[0]));
                    this.nomeEmpresaAcesso = empresa[0].nome;
                    this.linkImagem = empresa[0].logotipo;
                    this.radioSelect = empresa[0]._id;
                    this.dialog = false;
                    window.location.href = "/painel";
                } else {
                    window.location.href = "/painel";
                }
            },

            modalAlterarEmpresa: function () {  
                this.dialog = true;
            },

            logout: function(){
                localStorage.clear();
                window.location.href = "/";
            }
        },
        created () {
            this.initValue();
        },
    }
</script>

<style scoped lang="scss">
    .backLogo {
        background: #181818;
        padding: 12.4px;
    }
    aside.float-left.sideMenu.v-navigation-drawer.v-navigation-drawer--floating.v-navigation-drawer--open.theme--dark {
        position: fixed;
        z-index: 1;
        min-height: 100vh;
        background: #222;
    }
    .itemSubGroup a {
        margin-left: 17px;
        border-left: 1px solid #FFF;
    }
    .lineSubItem {
        width: 17px;
        margin-left: -17px;
        margin-right: 5px;
        border-bottom: 1px solid white;
        float: left;    
    }
    .textFirstLine, 
    .textSecondLine {
        margin: 0;
        font-size: 12px;
        width: 100%;
    }
    .textSecondLine {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .blockCompany {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        position: absolute;
        bottom: 40px;
    }
    .v-list-item__title {
        color: white !important;
    }
    .theme--dark.v-list-item,
    .v-list-group.itemSubGroup {
        border-bottom: 1px solid #e3e3e3;
    }
    .v-list-group.itemSubGroup a.v-list-item.v-list-item--link.theme--dark {
        border-bottom: none;
    }
    .theme--dark.v-list-item--active:focus:before, 
    .theme--dark.v-list-item.v-list-item--highlighted:before {
        opacity: 0;
    }
    a.v-list-item--active.v-list-item.v-list-item--link.theme--dark {
        background: #ea6936;
    }
    @media only screen and (max-width: 992px) {
        aside.float-left.sideMenu.v-navigation-drawer.v-navigation-drawer--floating.v-navigation-drawer--open.theme--dark {
            display: none;
            top: 70px !important;
        }
        .blockCompany {
            position: relative;
            bottom: 0;
        }
    }
</style>