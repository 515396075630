<template>
    <v-app>

        <Alert />

        <div class="app"> 

            <SideMenu /> 

            <Header />
        
            <v-main class="content">        
                <div class="cardContent">
                    <v-card class="cardItem">
                        <form>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Editar Tipo</h3>
                                </v-col>
                            </v-row>                            

                            <v-row>
                                <v-col cols="12" md="3">
                                    <v-select
                                        :items="['Ativo', 'Inativo']"
                                        v-model="status"
                                        label="Status"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>  
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field
                                        v-model="nome"
                                        label="Nome"
                                        required
                                        solo
                                        light
                                        v-mask="customTokens"
                                    ></v-text-field>
                                </v-col>
                            </v-row>                        

                            <v-row class="rowButton">
                                <v-col cols="12" md="12">
                                    <v-btn
                                        small                                            
                                        color="#ea6936" 
                                        dark 
                                        v-on:click="salvarDados"
                                    >
                                        Salvar Dados
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </form>
                    </v-card>
                </div>
            </v-main>
        </div>   
    
    </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';
    import http from '@/_services/http';
    import store from "@/_store";
    import {mask} from 'vue-the-mask';

  export default {
    components: {
        Alert,
        Header,
        SideMenu
    },

    directives: {
        mask
    },
    data () {
        return {   
            status: "",
            nome: "",
            id: "",
            customTokens: {
                mask: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
                tokens: {
                    X: {
                        pattern: /^[a-zA-Z]*$/,
                        transform: v => v.toLocaleUpperCase()
                    }
                }
            },
        }
    },
    methods: {

        initValue: function(){
            let id = window.location.pathname;
            id = id.split("/");
            id = id[3];
            let url = "types/get/" + id;
            this.idEmpresa = id;
            http.get(url)
                .then((response) => {
                    this.tipo = response.data.Response;
                    if(this.tipo.ativo){
                        this.status = "Ativo";
                    } else {
                        this.status = "Inativo";
                    }
                    this.nome = this.tipo.nome;
                    this.id = this.tipo._id;
                })
                .catch((error) => {
                    console.log(error);
                    store.dispatch("Notification/alert", {
                        type: "error",
                        message: "Ocorreu um erro",
                        timeClose: 3000
                    });
                });
        },        

        salvarDados: function(){
            let flagAtivo = false;
            if(this.status === "Ativo"){
                flagAtivo = true;
            }
            let objEnvio = {
                "id": this.id,
                "ativo": flagAtivo,
                "nome": this.nome
            };
            let url = "types/edit/" + this.id;
            http.post(url, objEnvio).then((response) => {
                if(!response.data.status) {
                    store.dispatch("Notification/alert", {
                        type: "warning",
                        message: response.data.message,
                        timeClose: 3000
                    });
                } else {                            
                    store.dispatch("Notification/alert", {
                        type: "info",
                        message: "Dados alterados com sucesso",
                        timeClose: 3000
                    });       
                    setTimeout(function(){
                        window.location.href = "/tipos";  
                    }, 2000);                              
                }
            }).catch((error) => {
                console.log(error);
                store.dispatch("Notification/alert", {
                    type: "error",
                    message: "Ocorreu um erro",
                    timeClose: 3000
                });
            });
        }

    },
    created () {
        this.initValue();
    },
  }  
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardItem {
        float: left;
        display: block;
        height: 100%;
    }
</style>