var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Alert'),_c('div',{staticClass:"app"},[_c('SideMenu'),_c('Header'),_c('v-main',{staticClass:"content"},[_c('div',{staticClass:"cardContent"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar Extintores","single-line":"","hide-details":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{attrs:{"small":"","color":"#ea6936","dark":""},on:{"click":function($event){return _vm.redirectPage('/extintor/novo')}}},[_vm._v(" Criar Extintor ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.base,"search":_vm.search,"sort-by":"id"},scopedSlots:_vm._u([{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [(item.ativo === true)?_c('div',[_vm._v(" Ativo ")]):_c('div',[_vm._v(" Inativo ")])]}},{key:"item.modelo",fn:function(ref){
var item = ref.item;
return [(item.modelo == 1)?_c('div',[_vm._v(" AMPOLA-CO2 ou N2 ")]):(item.modelo == 2)?_c('div',[_vm._v(" AP ")]):(item.modelo == 3)?_c('div',[_vm._v(" API ")]):(item.modelo == 4)?_c('div',[_vm._v(" CLASSE D ")]):(item.modelo == 5)?_c('div',[_vm._v(" CLASSE K ")]):(item.modelo == 6)?_c('div',[_vm._v(" CO2 ")]):(item.modelo == 7)?_c('div',[_vm._v(" EM ")]):(item.modelo == 8)?_c('div',[_vm._v(" EMI ")]):(item.modelo == 9)?_c('div',[_vm._v(" FE36 ")]):(item.modelo == 10)?_c('div',[_vm._v(" PQS ABC ")]):(item.modelo == 11)?_c('div',[_vm._v(" PQS-BC ")]):(item.modelo == 12)?_c('div',[_vm._v(" PQSI ABC ")]):(item.modelo == 13)?_c('div',[_vm._v(" PQSI-BC ")]):_vm._e()]}},{key:"item.local",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showNameLocal(item.local))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","color":"#ea6936","dark":""},on:{"click":function($event){return _vm.redirectPage('/extintor/editar/', item._id)}}},[_vm._v(" Editar ")])]}}],null,true)})],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }