<template>
    <div class="header">        
        <v-toolbar color="#FDFDFD" fixed height="70" class="barra-topo">      

            <v-icon light @click="openSideMenu()" class="iconMobileMenu">mdi-menu</v-icon>        
                
            <v-toolbar-title class="titleToolbar black--text">
                <span class="textBar" id="textBar">{{ nomeEmpresaAcesso }}</span>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <span class="lineVertical"></span>

            <v-icon light @click="goTo()" class="iconBell">mdi-bell</v-icon>                

        </v-toolbar>      
    </div>
</template>

<script>
  export default {
    data: () => ({
        nomeEmpresaAcesso: "",
    }),
    created () {
        this.initValue();
    },
    methods: {
        initValue: function(){
            let company = JSON.parse(localStorage.getItem("company"));
            let user = JSON.parse(localStorage.getItem("user"));
            if(user.permissao !== 0){
                if(company){
                    this.nomeEmpresaAcesso = company.nome;
                }
            } else {
                this.nomeEmpresaAcesso = "SISTEMA DE INSPEÇÃO DE EQUIPAMENTOS DE COMBATE A INCÊNDIO E EMERGÊNCIA";
            }
        },
        
        goTo: function() {
            window.location.href = "/notificacoes";
        },
    
        openSideMenu: function() {
            let sideMenu = document.getElementsByClassName('sideMenu');
            if(sideMenu[0].style.display == "block"){
                sideMenu[0].style.display = "none";
            } else {
                sideMenu[0].style.display = "block";
            }
        }

    }
  }
</script> 

<style scoped lang="scss">
    .iconMobileMenu {
        display: none !important;
    }
    .header {
        position: fixed;
        width: calc(100% - 256px);
        left: 256px;
        height: 70px;
        z-index: 1;
    }
    .titleToolbar {
        padding-left: 0 !important;
        width: auto;               
    }
    .textBar {
        font-size: 20px;
        font-weight: bold;
    }
    aside.float-left.sideMenu.v-navigation-drawer.v-navigation-drawer--floating.v-navigation-drawer--open.theme--dark {
        min-height: 100vh;
    }
    .logoImage {
        margin: 20px;
        height: 30px;
    }
    header.barra-topo {
        border-bottom: 1px solid #e3e3e3 !important;
        box-shadow: none !important;
    }
    .lineVertical {
        border-left: 1px solid #e3e3e3;
        height: 55px;
        width: 15px;
    }
    button.v-icon.notranslate.v-icon--link.mdi.mdi-bell.theme--light {
        float: right;
        width: 40px;
        height: 40px;
    }
    .itemSubGroup a {
        margin-left: 17px;
        border-left: 1px solid #FFF;
    }
    .lineSubItem {
        width: 17px;
        margin-left: -17px;
        margin-right: 5px;
        border-bottom: 1px solid white;
        float: left;    
    }
    @media only screen and (max-width: 992px) {
        .titleToolbar {
            padding-left: 0 !important;
            width: 100%;      
            text-align: center;      
        }
        .header {
            width: 100%;
            left: 0;
        }
        .iconMobileMenu {
            display: block !important;
            margin-right: 10px;
            top: -1px;
        }
        .iconBell {
            display: none !important;
        }
    }
</style>