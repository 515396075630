<template>
    <v-app>

        <Alert />
    
        <div class="app"> 

            <SideMenu /> 

            <Header />
        
            <v-main class="content">        
                <div class="cardContent">
                    <v-card class="cardItem">
                        <form>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Editar Local de Equipamento</h3>
                                </v-col>
                            </v-row>                            

                            <v-row>
                                <v-col cols="12" md="3">
                                    <v-select
                                        :items="['Ativo', 'Inativo']"
                                        v-model="status"
                                        label="Status"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>  
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="nome"
                                        label="Nome"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select
                                        :items="listaModelo"
                                        item-text="value"
                                        item-value="id"
                                        v-model="modelo"
                                        label="Modelos"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-textarea
                                        v-model="descricao"
                                        solo
                                        light
                                        label="Descrição"
                                    ></v-textarea>
                                </v-col>
                            </v-row>                           

                            <v-row class="rowButton">
                                <v-col cols="12" md="12">
                                    <v-btn
                                        small                                            
                                        color="#ea6936" 
                                        dark 
                                        v-on:click="salvarDados"
                                    >
                                        Salvar Dados
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </form>
                    </v-card>
                </div>
            </v-main>
        </div>   
    
    </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';
    import http from '@/_services/http';
    import store from "@/_store";

  export default {
    components: {
        Alert,
        Header,
        SideMenu
    },
    data () {
        return {   
            id: "",
            status: "",
            nome: "",
            descricao: "",
            listaModelo: ["Extintor", "Hidrante", "Personalizada"],
            modelo: {},
        }
    },
    methods: {
        initValue: function(){
            let id = window.location.pathname;
            id = id.split("/");
            id = id[3];
            let url = "locals/get/" + id;
            this.id = id;
            http.get(url)
                .then((response) => {
                    if(response.data.Response.ativo){
                        this.status = "Ativo";
                    } else {
                        this.status = "Inativo";
                    }
                    this.nome = response.data.Response.nome;
                    this.descricao = response.data.Response.descricao;
                    this.modelo = response.data.Response.modelo;
                })
                .catch((error) => {
                    console.log(error);
                    store.dispatch("Notification/alert", {
                        type: "error",
                        message: "Ocorreu um erro",
                        timeClose: 3000
                    });
                });
        },

        salvarDados: function(){
            if(!this.nome || !this.descricao || !this.modelo){
                store.dispatch("Notification/alert", {
                    type: "warning",
                    message: "Por favor, preencher todos os dados",
                    timeClose: 3000
                });
            } else {
                let flagAtivo = false;
                if(this.status === "Ativo"){
                    flagAtivo = true;
                }
                let objEnvio = {
                    "id": this.id,
                    "ativo": flagAtivo,
                    "nome": this.nome,
                    "descricao": this.descricao,
                    "modelo": this.modelo
                };
                let url = "locals/edit/" + this.id;
                http.post(url, objEnvio).then((response) => {
                    if(!response.data.status) {
                        store.dispatch("Notification/alert", {
                            type: "warning",
                            message: response.data.message,
                            timeClose: 3000
                        });
                    } else {                            
                        store.dispatch("Notification/alert", {
                            type: "info",
                            message: "Dados alterados com sucesso",
                            timeClose: 3000
                        });       
                        setTimeout(function(){
                            window.location.href = "/localEquipamento";  
                        }, 2000);                              
                    }
                }).catch((error) => {
                    console.log(error);
                    store.dispatch("Notification/alert", {
                        type: "error",
                        message: "Ocorreu um erro",
                        timeClose: 3000
                    });
                });
            }
        }
    },

    created () {
        this.initValue();
    },
  }  
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardItem {
        float: left;
        display: block;
        height: 100%;
    }
</style>