<template> 
    <v-layout wrap id="barraAlerta">
        <v-flex xs12 sm12 md12 lg12 xl12>
            <v-alert
                :value="alert.statusAlert"                
                class="mt-0" 
                :color="alert.typeAlert"
            >
                <span class="textMsg">{{ alert.messageAlert }}</span>
            </v-alert>
        </v-flex>
    </v-layout>
</template> 

<script>

    import { mapState } from 'vuex';

    export default {
        computed: {
            ...mapState({
                alert: state => state.Notification.alert
            })
        }
    }

</script>

<style lang="scss" scoped>
    .v-alert.mt-0.v-sheet.theme--light.info {
        border-radius: 0;    
        color: white;    
    }
    #barraAlerta {
        position: fixed;
        width: 100%;
        z-index: 9999;
        .textMsg {
            color: white;
        }
    }
</style>