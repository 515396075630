<template>
    <v-app>
        
        <Alert />

        <div class="app"> 

            <SideMenu /> 

            <Header />
        
            <v-main class="content">        
                <div class="cardContent">
                    <v-card class="cardItem">
                        <form>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Criar Novo Extintor</h3>
                                </v-col>
                            </v-row> 

                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-select
                                        :items="listaEmpresa"
                                        v-model="empresaSelecionada"
                                        label="Empresa"
                                        item-text="nome"
                                        item-value="_id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="10">
                                    <v-select
                                        :items="listaEquipamentos"
                                        v-model="equipamento"
                                        label="Local Equipamento"
                                        item-text="nome"
                                        item-value="_id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="2" style="padding-top: 20px;">
                                    <v-btn
                                        small                                            
                                        color="#ea6936" 
                                        dark 
                                        v-on:click="modalLocal"
                                    >
                                        Novo Local
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model="seloInmetro"
                                        label="Selo do Inmetro"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model="chassi"
                                        label="Chassi"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model="tipo"
                                        label="Tipo"
                                        required
                                        solo
                                        light
                                    ></v-text-field>
                                </v-col>
                            </v-row>      

                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-select
                                        :items="[
                                            'Ativo', 
                                            'Vencido', 
                                            'Manutenção', 
                                            'Inativo', 
                                            'Condenado'
                                        ]"
                                        v-model="situacao"
                                        label="Situação"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-select
                                        :items="listaModelos"
                                        v-model="modelo"
                                        label="Modelo"
                                        item-text="value"
                                        item-value="id"
                                        v-on:change="filterPeso(`${modelo}`)"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-select
                                        :items="listaPesoFiltrada"
                                        v-model="peso"
                                        label="Peso"
                                        item-text="value"
                                        item-value="id"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>                                
                            </v-row>    

                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model="dataUltimaRecarga"
                                        label="Mês/Ano da Última Recarga"
                                        v-on:blur="setProximaRegarga(1, `${dataUltimaRecarga}`)"
                                        required
                                        solo
                                        light
                                        v-mask="'##/####'"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-select
                                        :items="['12 Meses', '36 Meses', '60 Meses']"
                                        v-model="validadeRecarga"
                                        label="Validade da Recarga"
                                        v-on:change="setProximaRegarga(2, `${validadeRecarga}`)"
                                        required
                                        solo
                                        light
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model="dataProximaRecarga"
                                        label="Mês/Ano da Próxima Recarga"
                                        required
                                        solo
                                        light
                                        disabled
                                    ></v-text-field>
                                </v-col>
                            </v-row>    

                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="anoTesteHidro"
                                        label="Ano do último teste hidrostático"
                                        required
                                        solo
                                        light
                                        v-on:blur="setProximoTeste(`${anoTesteHidro}`)"
                                        v-mask="'####'"
                                    ></v-text-field>                                    
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="anoProximoTesteHidro"
                                        label="Ano do próximo teste hidrostático"
                                        required
                                        solo
                                        light
                                        disabled
                                    ></v-text-field>                                    
                                </v-col>                                
                            </v-row>     

                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="ultimaPesagem"
                                        label="Última pesagem semestral CO²(kg)"
                                        v-on:blur="setProximaPesagem(`${ultimaPesagem}`)"
                                        required
                                        solo
                                        light
                                        v-mask="'##/####'"
                                    ></v-text-field>                                    
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="proximaPesagem"
                                        label="Próxima pesagem semestral CO²(kg) "
                                        required
                                        solo
                                        light
                                        disabled
                                    ></v-text-field>                                    
                                </v-col>
                            </v-row>        

                            <v-row class="rowButton">
                                <v-col cols="12" md="12">
                                    <v-btn
                                        small                                            
                                        color="#ea6936" 
                                        dark 
                                        v-on:click="salvarDados"
                                    >
                                        Salvar Dados
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </form>
                    </v-card>
                </div>
            </v-main>

            <v-dialog v-model="dialog" width="500px">            
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Local de Equipamento</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="nomeLocal"
                                    label="Nome"
                                    required
                                    solo
                                    light
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="#ea6936"
                            text
                            @click="dialog = false"
                        >
                            Fechar
                        </v-btn>
                        <v-btn
                            color="#ea6936"
                            text
                            @click="salvarLocal"
                        >
                            Salvar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </div>   
    
    </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';
    import {mask} from 'vue-the-mask';
    import http from '@/_services/http';
    import store from "@/_store";
    import moment from 'moment';

    export default {
        components: {
            Alert,
            Header,
            SideMenu
        },
        directives: {
            mask
        },
        data () {
            return {   
                nomeLocal: "",
                dialog: false,
                listaEquipamentos: [],
                equipamento: "",
                seloInmetro: "",
                chassi: "",
                tipo: "",
                listaModelos: [
                    { id: 1, value: "AMPOLA-CO2 ou N2" },
                    { id: 2, value: "AP" },
                    { id: 3, value: "API" },
                    { id: 4, value: "CLASSE D" },
                    { id: 5, value: "CLASSE K" },
                    { id: 6, value: "CO2" },
                    { id: 7, value: "EM" },
                    { id: 8, value: "EMI" },
                    { id: 9, value: "FE36" },
                    { id: 10, value: "PQS ABC" },
                    { id: 11, value: "PQS-BC" },
                    { id: 12, value: "PQSI ABC" },
                    { id: 13, value: "PQSI-BC" }
                ],
                modelo: { id: "", value: "" },
                peso: "",            
                dataUltimaRecarga: "",
                dataProximaRecarga: "",
                validadeRecarga: "",
                situacao: "",
                ultimaPesagem: "",
                proximaPesagem: "",
                anoTesteHidro: "",
                anoProximoTesteHidro: "",
                listaEmpresa: [],
                empresaSelecionada: "",
                listaPesoFiltrada: [],
                listaPeso: [
                    { id: 1, value: '1KG' },
                    { id: 2, value: '1.5KG' },
                    { id: 3, value: '2KG' },
                    { id: 4, value: '2.3KG' },
                    { id: 5, value: '2.5KG' },
                    { id: 6, value: '5KG' },
                    { id: 7, value: '3KG' },
                    { id: 8, value: '4KG' },
                    { id: 9, value: '4.5KG' },
                    { id: 10, value: '6KG' },
                    { id: 11, value: '8KG' },
                    { id: 12, value: '9KG' },
                    { id: 13, value: '10KG' },
                    { id: 14, value: '12KG' },
                    { id: 15, value: '20KG' },
                    { id: 16, value:  '25KG' },
                    { id: 17, value: '30KG' },
                    { id: 18, value: '45KG' },
                    { id: 19, value: '50KG' },
                    { id: 20, value: '55KG' },
                    { id: 21, value: '70KG' },
                    { id: 22, value: '100KG' },
                    { id: 23, value: '80GR' },
                    { id: 24, value: '100GR' },
                    { id: 25, value: '200GR' },
                    { id: 26, value: '300GR' },
                    { id: 27, value: '400GR' },
                    { id: 28, value: '450GR' },
                    { id: 29, value: '500GR' },
                    { id: 30, value: '600GR' },
                    { id: 31, value: '700GR' },
                    { id: 32, value: '750GR' },
                    { id: 33, value: '900GR' },
                    { id: 34, value: '6L' },
                    { id: 35, value: '9L' },
                    { id: 36, value: '10L' },
                    { id: 37, value: '50L' },
                    { id: 38, value: '75L' },
                    { id: 39, value: '100L' }
                ]
            }
        },
        created () {
            this.initialize();
        },
        methods: {
            
            initialize() {
                http.get("locals/getAll")
                    .then((response) => {
                        this.listaEquipamentos = response.data.Response;
                        this.buscarEmpresa();
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            buscarEmpresa(){
                http.get("companies/getAll")
                    .then((response) => {
                        this.listaEmpresa = response.data.Response.filter(item => {
                            return item.ativo == true;
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            filterPeso(modeloSel){   
                let filter = {};
                if(modeloSel == 1){
                    filter.id = [1, 2, 3, 7, 8, 10, 13, 14, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33 ]
                }  
                else if(modeloSel == 2 || modeloSel == 3){
                    filter.id = [36, 38];
                }   
                else if(modeloSel == 4){
                    filter.id = [12];
                }  
                else if(modeloSel == 5){
                    filter.id = [10, 12];
                }  
                else if(modeloSel == 6){
                    filter.id = [1, 3, 8, 10, 13, 15, 16, 17, 18];
                }  
                else if(modeloSel == 7 || modeloSel == 8){
                    filter.id = [35, 36, 37, 39];
                }  
                else if(modeloSel == 9){
                    filter.id = [5, 6];                   
                }  
                else if(modeloSel == 10){                    
                    filter.id = [1, 3, 4, 5, 8, 9, 10, 11, 12, 14, 15, 16, 17, 19, 20, 21, 22];
                }  
                else if(modeloSel == 11){                    
                    filter.id = [1, 3, 5, 8, 10, 11, 14, 15, 16, 17, 19, 21, 22]; 
                }  
                else if(modeloSel == 12){                    
                    filter.id = [8, 10, 11, 14, 15, 16, 17, 19, 21, 22];
                }  
                else if(modeloSel == 13){                    
                    filter.id = [8, 10, 11, 14, 15, 16, 17, 19, 21, 22];  
                }      
                this.listaPesoFiltrada = this.listaPeso.filter(function(x) {
                    return Object.keys(filter).every(function(f) {
                        return filter[f].some(function(z) {
                            return z == x[f];
                        })
                    })
                });
            },

            setProximaRegarga(tipo, e){
                if(tipo == 1){
                    if(this.validadeRecarga){
                        let days = this.validadeRecarga.split(" ");
                        days = days[0];
                        let new_date = "";
                        let startdate = "01/" + e;
                        if(days == 12){
                            new_date = moment(startdate, "DD/MM/YYYY").add(365, 'days');
                        }
                        else if(days == 36){
                            new_date = moment(startdate, "DD/MM/YYYY").add(1095, 'days');
                        }
                        else if(days == 60){
                            new_date = moment(startdate, "DD/MM/YYYY").add(1825, 'days');
                        }
                        this.dataProximaRecarga = new_date.format("MM/YYYY"); 
                    }
                } 
                else if(tipo == 2){
                    let days = e.split(" ");
                    days = days[0];
                    let new_date = "";
                    let startdate = "01/" + this.dataUltimaRecarga;
                    let calc = (days/12) * 365;
                    if(days == 12){                        
                        new_date = moment(startdate, "DD/MM/YYYY").add(calc, 'days');
                    }
                    else if(days == 36){
                        new_date = moment(startdate, "DD/MM/YYYY").add(calc, 'days');
                    }
                    else if(days == 60){
                        new_date = moment(startdate, "DD/MM/YYYY").add(calc, 'days');
                    }
                    this.dataProximaRecarga = new_date.format("MM/YYYY");
                }
            },

            setProximoTeste(e){
                let startdate = "31/12/" + e;
                let calc = (60/12) * 365;
                let new_date = moment(startdate, "DD/MM/YYYY").add(calc, 'days').format('YYYY');
                this.anoProximoTesteHidro = new_date;
            },

            setProximaPesagem(e){
                let startdate = "01/" + e;
                let calc = 7 * 30;
                let new_date = moment(startdate, "DD/MM/YYYY").add(calc, 'days').format('MM/YYYY');
                this.proximaPesagem = new_date;
            },

            salvarDados(){ 
                let objEnvio = {
                    "empresa": this.empresaSelecionada,
                    "local": this.equipamento,
                    "inspecao": null,
                    "selo_inmetro": this.seloInmetro,
                    "chassi": this.chassi,
                    "tipo": this.tipo,
                    "modelo": this.modelo,
                    "peso": this.peso,                    
                    "mes_ano": this.dataUltimaRecarga,
                    "validade": this.validadeRecarga,
                    "mes_ano_recarga": this.dataProximaRecarga,
                    "ano_ultimo_teste": this.anoTesteHidro,
                    "ano_proximo_teste": this.anoProximoTesteHidro,
                    "ultima_pesagem": this.ultimaPesagem,
                    "proxima_pesagem": this.proximaPesagem,
                    "status": this.situacao,
                    "ativo": true
                }  
                console.log(objEnvio);
                http.post("extinguishers/add", objEnvio).then((response) => {
                    if(!response.data.status) {
                        store.dispatch("Notification/alert", {
                            type: "warning",
                            message: response.data.message,
                            timeClose: 3000
                        });
                    } else {                            
                        store.dispatch("Notification/alert", {
                            type: "info",
                            message: "Dados salvos com sucesso",
                            timeClose: 3000
                        });       
                        setTimeout(function(){
                            window.location.href = "/extintores";  
                        }, 2000);                              
                    }
                }).catch((error) => {
                    console.log(error);
                    store.dispatch("Notification/alert", {
                        type: "error",
                        message: "Ocorreu um erro",
                        timeClose: 3000
                    });
                });
            },

            salvarLocal(){
                let objEnvio = {
                    "nome": this.nomeLocal,
                    "modelo": "Extintor",
                    "descricao": "-"
                };
                http.post("locals/add", objEnvio).then((response) => {
                    if(!response.data.status) {
                        store.dispatch("Notification/alert", {
                            type: "warning",
                            message: response.data.message,
                            timeClose: 3000
                        });
                    } else {           
                        store.dispatch("Notification/alert", {
                            type: "info",
                            message: "Dados salvos com sucesso",
                            timeClose: 3000
                        });   
                        setTimeout(function(){
                            window.location.href = "/extintor/novo";
                        }, 2000);                                            
                    }
                }).catch((error) => {
                    console.log(error);
                    store.dispatch("Notification/alert", {
                        type: "error",
                        message: "Ocorreu um erro",
                        timeClose: 3000
                    });
                });
            },

            modalLocal(){
                this.dialog = true;
            },
        }
    }  
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardItem {
        float: left;
        display: block;
        height: 100%;
    }
</style>