<template>
    <v-app>

        <Alert />
    
        <div class="app"> 

            <SideMenu /> 

            <Header />
     
            <v-main class="content">        
                <div class="cardContent">
                    <v-card class="cardItem">
                        
                        <form>
                            
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Perguntas dos Personalizados</h3>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="3">
                                    <v-select
                                        :items="listipos"
                                        v-model="tipo"
                                        label="Tipos"
                                        required
                                        solo
                                        light
                                        item-text="nome"
                                        item-value="_id"
                                    ></v-select>
                                </v-col>  
                                <v-col cols="12" md="9" class="mt-3">
                                    <v-btn
                                        small                                            
                                        color="#ea6936" 
                                        dark 
                                        v-on:click="buscar"
                                    >
                                        Buscar
                                    </v-btn>
                                </v-col>                             
                            </v-row>
                        </form>
                        
                        <v-row v-show='notquestion'>
                            <v-col cols="12" md="3">
                                <p>Sem perguntas cadastradas</p>
                            </v-col>
                            <v-col cols="12" md="9">
                                <v-btn
                                    small                                            
                                    color="#ea6936" 
                                    dark 
                                    v-on:click="cadastrar"
                                >
                                    Cadastrar
                                </v-btn>
                            </v-col>      
                        </v-row>

                        <v-row v-show='withquestion'>
                            <v-row>
                                <v-col cols="12" md="10">
                                    <v-text-field
                                        v-model="pergunta"
                                        label="Pergunta"
                                        required
                                        solo
                                        light
                                    ></v-text-field>           
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-btn
                                        small                                            
                                        color="#ea6936" 
                                        dark 
                                        v-on:click="adicionar"
                                        class="mt-2"
                                    >
                                        Adicionar
                                    </v-btn>       
                                </v-col>
                            </v-row> 
                            <v-col cols="12" md="12">
                                <v-data-table
                                    :headers="headers"
                                    :items="listaPerguntas"
                                    :search="search"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-btn
                                            x-small                                            
                                            color="#ea6936" 
                                            dark 
                                            @click="excluir(item._id)"
                                        >
                                            Excluir
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>

                    </v-card>
                </div>
            </v-main>
    
        </div>   
  
    </v-app>
</template>

<script>
    import Alert from '@/_components/shared/Alert';
    import Header from '@/_components/Header';
    import SideMenu from '@/_components/SideMenu';
    import http from '@/_services/http';
    import store from "@/_store"; 

    export default {
        components: {
            Alert,
            Header,
            SideMenu
        },

        data: () => ({
            listipos: [],
            tipo: "",
            notquestion: false,
            withquestion: false,
            listaPerguntas: [],
            pergunta: "",
            search: '',
            headers: [
                { 
                    text: 'Id', 
                    align: 'left',
                    value: '_id', 
                    sortable: true,                     
                    filterable: false   
                },
                { 
                    text: 'Pergunta', 
                    align: 'left',
                    value: 'pergunta', 
                    sortable: true,                     
                    filterable: true   
                },
                { 
                    text: '', 
                    value: 'actions',
                    sortable: false, 
                    filterable: false 
                },
            ]
        }),

        created () {
            this.initialize();
        },

        methods: {

            initialize () {
                http.get("types/getAll")
                    .then((response) => {                    
                        this.listipos = response.data.Response.filter(item => {
                            if(item.ativo == true && item.nome !== "EXTINTOR" && item.nome !== "HIDRANTE"){
                                return true;
                            }
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            buscar(){
                let url = "questions/getByType/" + this.tipo;
                http.get(url)
                    .then((response) => {                    
                        if(response.data.status){
                            if(response.data.Response.length > 0){
                                this.withquestion = true; 
                                this.listaPerguntas = response.data.Response;  
                            } else {
                                this.notquestion = true; 
                            }
                        } else {
                            store.dispatch("Notification/alert", {
                                type: "error",
                                message: response.data.message,
                                timeClose: 3000
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });
            },

            cadastrar (){
                let tipo = this.listipos.filter(item => {
                    return item._id == this.tipo;
                });
                window.localStorage.setItem("tipoSelecionado", JSON.stringify(tipo[0]));
                window.location.href = "/perguntas/personalizados/add";
            },

            adicionar(){     
                let objEnvio = {
                    pergunta: this.pergunta,
                    tipo: this.tipo,
                    ativo: true
                }
                http.post("questions/add", objEnvio)
                    .then((response) => {                    
                        if(!response.data.status) {
                            store.dispatch("Notification/alert", {
                                type: "warning",
                                message: response.data.message,
                                timeClose: 3000
                            });
                        } else {                            
                            store.dispatch("Notification/alert", {
                                type: "info",
                                message: "Dados adicionado com sucesso",
                                timeClose: 3000
                            });   
                            this.pergunta = "";     
                            this.buscar();                                                     
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch("Notification/alert", {
                            type: "error",
                            message: "Ocorreu um erro",
                            timeClose: 3000
                        });
                    });     
            },

            excluir(id){
               let url = "questions/remove/" + id;
                http.get(url)
                    .then((response) => {                    
                            if(!response.data.status) {
                                store.dispatch("Notification/alert", {
                                    type: "warning",
                                    message: response.data.message,
                                    timeClose: 3000
                                });
                            } else {                            
                                this.buscar();                          
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            store.dispatch("Notification/alert", {
                                type: "error",
                                message: "Ocorreu um erro",
                                timeClose: 3000
                            });
                        });   
            }

        },
    }
</script>

<style scoped lang="scss">  
    .cardContent {
        display: flex;
        justify-content: center;
    }
    .cardContent .container {
        padding: 0;
    }
    .cardTabPerfil {
        padding: 20px;
    }
    .v-tab.v-tab {
        font-weight: bold;
    }
    .v-window.v-item-group.theme--light.v-tabs-items {
        padding: 40px 5px 0px 5px;
    }
    .rowButton {
        text-align: center;
    }
</style>