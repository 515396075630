import Vue from 'vue'
import VueRouter from 'vue-router'

import NotFound from "@/views/NotFound.vue";
import Login from "@/views/auth/Login.vue";
import ForgotPass from "@/views/auth/ForgotPass.vue";
import Home from "@/views/Home.vue";

//PERFIL
import Perfil from "@/views/perfil/Index.vue";

/* //CADASTROS  */
  //USUARIOS
  import ListUser from "@/views/register/users/List.vue";
  import NewUser from "@/views/register/users/New.vue";
  import EditUser from "@/views/register/users/Edit.vue";

  //EMPRESAS
  import ListCompany from "@/views/register/companies/List.vue";
  import NewCompany from "@/views/register/companies/New.vue";
  import EditCompany from "@/views/register/companies/Edit.vue";

  //LOCAL DE EQUIPAMENTO
  import LocationEquipment from "@/views/register/locationEquipment/List.vue";
  import NewLocationEquipment from "@/views/register/locationEquipment/New.vue";
  import EditLocationEquipment from "@/views/register/locationEquipment/Edit.vue";

  //TIPO
  import Type from "@/views/register/type/List.vue";
  import NewType from "@/views/register/type/New.vue";
  import EditType from "@/views/register/type/Edit.vue";

  //TIPO DE OCORRENCIA
  import TypeOcurrencie from "@/views/register/typeOcurrencies/List.vue";
  import NewTypeOcurrencie from "@/views/register/typeOcurrencies/New.vue";
  import EditTypeOcurrencie from "@/views/register/typeOcurrencies/Edit.vue";

  //EXTINTORES
  import FireExtinguishers from "@/views/register/fireExtinguishers/List.vue";
  import NewFireExtinguishers from "@/views/register/fireExtinguishers/New.vue";
  import EditFireExtinguishers from "@/views/register/fireExtinguishers/Edit.vue";

  //HIDRANTE
  import Hydrant from "@/views/register/hydrant/List.vue";
  import NewHydrant from "@/views/register/hydrant/New.vue";
  import EditHydrant from "@/views/register/hydrant/Edit.vue";

  //PERSONALIZADOS
  import Custom from "@/views/register/custom/List.vue";
  import NewCustom from "@/views/register/custom/New.vue";
  import EditCustom from "@/views/register/custom/Edit.vue";
  
/* //CADASTROS  */

//RELATORIOS
import ReportFireExtinguishers from "@/views/reports/fireExtinguishers/List.vue";
import ReportHydrant from "@/views/reports/hydrant/List.vue";
import ReportCustom from "@/views/reports/custom/List.vue";

//INSPEÇÕES
import InspectionsFireExtinguishers from "@/views/inspections/fireExtinguishers/List.vue";
import InspectionsHydrant from "@/views/inspections/hydrant/List.vue";
import InspectionsCustom from "@/views/inspections/custom/List.vue";

//MANUTENÇÃO
import MaintenanceFireExtinguishers from "@/views/inspections/fireExtinguishers/ListMaintenance.vue";
import MaintenanceHydrant from "@/views/inspections/hydrant/ListMaintenance.vue";
import MaintenanceCustom from "@/views/inspections/custom/ListMaintenance.vue";

//QUESTOES
import QuestionsFireExtinguishers from "@/views/questions/FireExtinguishers.vue";
import QuestionsHydrant from "@/views/questions/Hydrant.vue";
import QuestionsCustom from "@/views/questions/Custom.vue";
import QuestionsCustomAdd from "@/views/questions/CustomAdd.vue";

Vue.use(VueRouter)

const routes = [

  /* ** ACESSO ** */
    {
      path: "/",
      name: "login",
      component: Login,
    },
    {
      path: "/esqueciSenha",
      name: "forgotPass",
      component: ForgotPass,
    },
  /* ** ACESSO ** */

  /* ** HOME ** */
    {
      path: "/painel",
      name: "home",
      component: Home,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },
  /* ** HOME ** */

  /* ** PERFIL ** */
    {
      path: "/perfil",
      name: "perfil",
      component: Perfil,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },
  /* ** PERFIL ** */

  /* ** USUARIOS ** */
    {
      path: "/usuarios",
      name: "listUser",
      component: ListUser,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },
    {
      path: "/usuarios/novo",
      name: "newUser",
      component: NewUser,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },
    {
      path: "/usuarios/editar/:id",
      name: "editUser",
      component: EditUser,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
  /* ** USUARIOS ** */

  /* ** EMPRESAS ** */
    {
      path: "/empresas",
      name: "listCompany",
      component: ListCompany,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },
    {
      path: "/empresas/nova",
      name: "newCompany",
      component: NewCompany,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },
    {
      path: "/empresas/editar/:id",
      name: "editCompany",
      component: EditCompany,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
  /* ** EMPRESAS ** */
  
  /* ** LOCAL DE EQUIPAMENTO ** */
    {
      path: "/localEquipamento",
      name: "locationEquipment",
      component: LocationEquipment,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
    {
      path: "/localEquipamento/novo",
      name: "newLocationEquipment",
      component: NewLocationEquipment,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },      
    {
      path: "/localEquipamento/editar/:id",
      name: "editLocationEquipment",
      component: EditLocationEquipment,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },      
  /* ** LOCAL DE EQUIPAMENTO ** */

  /* ** TIPO ** */
    {
      path: "/tipos",
      name: "type",
      component: Type,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
    {
      path: "/tipo/novo",
      name: "newType",
      component: NewType,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
    {
      path: "/tipo/editar/:id",
      name: "editType",
      component: EditType,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
  /* ** TIPO ** */

  /* ** HIDRANTE ** */
    {
      path: "/hidrantes",
      name: "hydrant",
      component: Hydrant,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
    {
      path: "/hidrante/novo",
      name: "newHydrant",
      component: NewHydrant,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
    {
      path: "/hidrante/editar/:id",
      name: "editHydrant",
      component: EditHydrant,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
  /* ** HIDRANTE ** */

  /* ** EXTINTORES ** */
    {
      path: "/extintores",
      name: "fireExtinguishers",
      component: FireExtinguishers,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
    {
      path: "/extintor/novo",
      name: "newFireExtinguishers",
      component: NewFireExtinguishers,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
    {
      path: "/extintor/editar/:id",
      name: "editFireExtinguishers",
      component: EditFireExtinguishers,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
  /* ** EXTINTORES ** */

  /* ** PERSONALIZADO ** */
    {
      path: "/personalizados",
      name: "custom",
      component: Custom,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
    {
      path: "/personalizado/novo",
      name: "newCustom",
      component: NewCustom,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
    {
      path: "/personalizado/editar/:id",
      name: "editCustom",
      component: EditCustom,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },  
  /* ** PERSONALIZADO ** */

  /* ** RELATÓRIOS ** */
    {
      path: "/relatorios/extintores",
      name: "reportFireExtinguishers",
      component: ReportFireExtinguishers,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },
    {
      path: "/relatorios/hidrantes",
      name: "reportHydrant",
      component: ReportHydrant,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },
    {
      path: "/relatorios/personalizados",
      name: "reportCustom",
      component: ReportCustom,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    },
  /* ** RELATÓRIOS ** */  

  /* ** INSPEÇÕES ** */
  {
    path: "/inspecoes/extintores",
    name: "inspectionsFireExtinguishers",
    component: InspectionsFireExtinguishers,
    beforeEnter: (to, from, next) => {
      checkUser(to, next);
    },
  },
  {
    path: "/inspecoes/hidrantes",
    name: "inspectionsHydrant",
    component: InspectionsHydrant,
    beforeEnter: (to, from, next) => {
      checkUser(to, next);
    },
  },
  {
    path: "/inspecoes/personalizados",
    name: "inspectionsCustom",
    component: InspectionsCustom,
    beforeEnter: (to, from, next) => {
      checkUser(to, next);
    },
  },
  /* ** INSPEÇÕES ** */

  /* ** MANUTENÇÃO ** */
  {
    path: "/manutencao/extintores",
    name: "maintenanceFireExtinguishers",
    component: MaintenanceFireExtinguishers,
    beforeEnter: (to, from, next) => {
      checkUser(to, next);
    },
  },
  {
    path: "/manutencao/hidrantes",
    name: "maintenanceHydrant",
    component: MaintenanceHydrant,
    beforeEnter: (to, from, next) => {
      checkUser(to, next);
    },
  },
  {
    path: "/manutencao/personalizados",
    name: "maintenanceCustom",
    component: MaintenanceCustom,
    beforeEnter: (to, from, next) => {
      checkUser(to, next);
    },
  },
  /* ** MANUTENÇÃO ** */

  /* ** PERGUNTAS ** */
  {
    path: "/perguntas/extintores",
    name: "questionsFireExtinguishers",
    component: QuestionsFireExtinguishers,
    beforeEnter: (to, from, next) => {
      checkUser(to, next);
    },
  },
  {
    path: "/perguntas/hidrantes",
    name: "questionsHydrant",
    component: QuestionsHydrant,
    beforeEnter: (to, from, next) => {
      checkUser(to, next);
    },
  },
  {
    path: "/perguntas/personalizados",
    name: "questionsCustom",
    component: QuestionsCustom,
    beforeEnter: (to, from, next) => {
      checkUser(to, next);
    },
  },
  {
    path: "/perguntas/personalizados/add",
    name: "questionsCustomAdd",
    component: QuestionsCustomAdd,
    beforeEnter: (to, from, next) => {
      checkUser(to, next);
    },
  },  
  /* ** PERGUNTAS ** */

  /* ** TIPOS DE OCORRENCIA ** */
    {
      path: "/tiposOcorrencias",
      name: "typeOcurrencie",
      component: TypeOcurrencie,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    }, 
    {
      path: "/tipoOcorrencia/novo",
      name: "newTypeOcurrencie",
      component: NewTypeOcurrencie,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    }, 
    {
      path: "/tipoOcorrencia/editar/:id",
      name: "editTypeOcurrencie",
      component: EditTypeOcurrencie,
      beforeEnter: (to, from, next) => {
        checkUser(to, next);
      },
    }, 
  /* ** TIPOS DE OCORRENCIA ** */

  /* ** ERROS ** */
    { 
      path: "*", 
      name: "notFound",
      component: NotFound
    }
  /* ** ERROS ** */

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function checkUser(to, next) {
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  if (authRequired && !loggedIn) {
    return next('/');
  }
  next();
}

export default router
